import React, { FC } from 'react';
import styled from 'styled-components';
import { Folder } from './Tools';
import { FaProjectDiagram } from 'react-icons/fa';

interface props {
    className?: string
}

const ProcedureListComp:FC <props> = ({ className }) => {

    const procedures = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];

    return (
        <div className={className}>
            <div className="container">
                {procedures.map(folder => {
                    return (
                    <div className="folder">
                    <Folder>
                        <FaProjectDiagram size={100} />
                        <p>Procedure {folder}</p>
                    </Folder>
                    </div>
                    );
                })}
            </div>
        </div>
    )
};

const ProcedureList = styled(ProcedureListComp)`

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    
    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        width: 100vw;
        border: 2px white dashed;
        border-radius: 10px;
        max-height: 40em;
        overflow-y: auto;

        & .folder {
            background-color: white;
            width: 10em;
            height: 10em;
            margin-left: 2em;
            margin-right: 2em;
            margin-top: 2em;
            margin-bottom: 2em;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            & svg {
                margin: auto;
            }
        }
    }

`

export default ProcedureList;