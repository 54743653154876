import { FC } from 'react';
import styled from 'styled-components';


const PromptComp:FC<IGlobalProps> = ({ className, children }) => {

    return (
        <div className={className}>
            {children}
        </div>
    )
};

const Prompt = styled(PromptComp)`

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100%;

    & button {
        height: 5em;
        width: 10em;
        border: none;
        background-color: salmon;
        border-radius: 10px;
        font-weight: 700;
        margin-bottom: 2em;
    }

    & button:hover {
        transform: scale(1.01);
    }

    & h3 {
        color: antiquewhite;
    }
`

export default Prompt;