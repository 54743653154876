import { NavItem } from "./app"
import { FaGraduationCap, FaBookOpen, FaPhotoVideo, FaHome } from 'react-icons/fa'
import { HiDocument, HiUserGroup } from 'react-icons/hi';
import { TbWorldWww, TbSection, TbCoffee } from 'react-icons/tb';
import { GiCube } from 'react-icons/gi';

export const QRLNav: NavItem[] = [
    {
        name: "Home",
        image: <FaHome size={50} />,
        link: "/qrl/home"
    },
    {
        name: "Documents",
        image: <HiDocument size={50} />,
        link: "/qrl/documentation"
    },
    {
        name: "Training",
        image: <FaGraduationCap size={50} />,
        link: "/qrl/training"

    },
    {
        name: "Media",
        image: <FaPhotoVideo size={50} />,
        link: "/qrl/media"

    },
    {
        name: "3D Models",
        image: <GiCube size={50} />,
        link: "/qrl/models"
    },
    {
        name: "Procedures",
        image: <FaBookOpen size={50} />,
        link: "/qrl/procedures"
    },
    {
        name: "External Content",
        image: <TbWorldWww size={50} />,
        link: "/qrl/external-content"
    },
]

export const BookNav: NavItem[] = [
    {
        name: "Home",
        image: <FaHome size={50} />,
        link: "/qbk/home"
    },
    {
        name: "Departments",
        image: <TbSection size={50} />,
        link: "/qbk/departments"
    },
    {
        name: "Teams",
        image: <HiUserGroup size={50} />,
        link: "/qbk/teams"
    },
    {
        name: "Stations",
        image: <TbCoffee size={50} />,
        link: "/qbk/stations"
    },
]