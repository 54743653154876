import { Lang, Dict } from "./Dict"

export const HI: Dict = new Lang(
    "हिंदी",
    "नमस्कार",
    "प्रलेखन",
    "नए अलर्ट (EWO)",
    "अपलोड",
    "उपकरण",
    "संख्या",
    "उपकरण संख्या",
    "कारखाना",
    "नियमावली",
    "मशीन",
    "विस्तार",
    "वीडियो",
    "चुने हुए",
    "एक उपकरण के लिए खोजें",
)