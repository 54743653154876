/*

DECISION TREE LOGIC FOR DECIDER

THIS CODE KIND OF WORKS DONT TOUCH

- ZB

*/

// #region TYPE / INTERFACE / DECLARATIONS

let anaylticsArr: any[] = []

enum tag {
  fabrication,
  CNC,
  assembly,
  finishing,
  lightweight,
  mediumweight,
  heavyweight,
  ultraweight,
  humanfriendly,
  nothumanfriendly,
  cheap,
  affordable,
  expensive,
  luxury,
  education,
  demo
}

export interface Robot<R> {
  id: number;
  name: string;
  tags: tag[];
  weights: R;
  score: number;
}
export interface Question {
  id: number;
  prompt: string
  choices: Choice<number[]>[];
}

export interface Choice<R> {
  choice: string,
  tag: tag;
  inputs: R
}

// #endregion

// #region DATASETS

  export const robots: Robot<number[]>[] = [
    { id: 1, name: "Boom Bot", tags: [tag.fabrication, tag.cheap, tag.humanfriendly], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 2, name: "Big Dawg", tags: [tag.CNC, tag.cheap, tag.humanfriendly], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 4, name: "Oozer", tags: [tag.fabrication, tag.affordable, tag.education], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 5, name: "Robo Drone", tags: [tag.fabrication, tag.cheap, tag.nothumanfriendly], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 6, name: "Ripley", tags: [tag.fabrication, tag.expensive, tag.demo], weights: [0.5, 0.5, 0.5], score: 0 },
    { id: 7, name: "Trev Bot", tags: [tag.fabrication, tag.luxury, tag.lightweight], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 8, name: "Brick", tags: [tag.assembly, tag.cheap, tag.humanfriendly], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 9, name: "Ethan 2.0", tags: [tag.assembly, tag.expensive, tag.demo], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 10, name: "The Dougster", tags: [tag.finishing, tag.affordable, tag.mediumweight], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 11, name: "Amytron", tags: [tag.finishing, tag.expensive, tag.humanfriendly], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 12, name: "Metro 2045", tags: [tag.CNC, tag.affordable, tag.ultraweight], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 13, name: "Bender", tags: [tag.CNC, tag.expensive, tag.demo], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 14, name: "Miami Cruise Bot", tags: [tag.finishing, tag.luxury, tag.humanfriendly], weights: [0.5, 0.5, 0.5,], score: 0 },
    { id: 15, name: "Gastonne", tags: [tag.CNC, tag.luxury, tag.mediumweight], weights: [0.5, 0.5, 0.5,], score: 0 },
  ];

  export const questions: Question[] = [
    { id: 1, prompt: "What industry are you in?", choices: [
      { choice: "Fab Shop", tag: tag.fabrication, inputs: [0.7, 0.5, 0.3]},
      { choice: "CNC Shop", tag: tag.CNC, inputs: [0.2, 0.5, 0.4]},
      { choice: "Assembly", tag: tag.assembly, inputs: [0.9, 0.7, 0.1]},
      { choice: "Finishing Shop", tag: tag.finishing, inputs: [0.9, 0.7, 0.1]}
    ]},
    { id: 2, prompt: "What is the average weight of the parts you'll be handling?", choices: [
      { choice: "0.5-2kg", tag: tag.lightweight, inputs: [0.7, 0.3, 0.9] },
      { choice: "2.5-5kg", tag: tag.mediumweight, inputs: [0.4, 0.4, 0.4]},
      { choice: "5.5-10kg", tag: tag.heavyweight, inputs: [0.2, 0.9, 0.2]},
      { choice: "10.5-25kg", tag: tag.ultraweight, inputs: [0.2, 0.9, 0.2]}
    ]},
    { id: 3, prompt: "Will your robot be working in close proximity to humans or have daily human interaction?", choices: [
      { choice: "Yes", tag: tag.humanfriendly, inputs: [0.34, 0.7, 0.96] },
      { choice: "No", tag: tag.nothumanfriendly, inputs: [0.73, 0.26, 0.85]},
      { choice: "Not sure", tag: tag.humanfriendly, inputs: [0.07, 0.00, 0.04]}
    ]},
    { id: 4, prompt: "What is the budget for the robot arm portion of your automation cell?", choices: [
      { choice: "Less than $10,000", tag: tag.cheap, inputs: [0.83, 0.3, 0.3] },
      { choice: "$10,001 - $35,000", tag: tag.affordable, inputs: [0.2, 0.7, 0.4]},
      { choice: "$35,001 - $50,000", tag: tag.expensive, inputs: [0.2, 0.4, 0.9]},
      { choice: "More than $50,000", tag: tag.luxury, inputs: [0.2, 0.4, 0.9]}
    ]},
    { id: 5, prompt: "Which of these offerings would make you more likely to buy and get started in automation?", choices: [
      { choice: "Discount off the price of the robot", tag: tag.affordable, inputs: [0.2, 0.2, 0.1] },
      { choice: "Training and a curriculum I can keep to train future workers", tag: tag.education, inputs: [0.5, 0.5, 0.5]},
      { choice: "Live demo at my facility, then a robot on cosignment to test it out", tag: tag.demo, inputs: [0.2, 0.5, 0.3]}
    ]},
  ]

// #endregion

// #region WEIGHT APPLICATION AND MATCHING

export const FindMatch = (choice: Choice<R>): Robot<number[]>[] => {
  const result: Robot<number[]>[] = robots.map((r: Robot<R>) => {
    r.tags.forEach(t => {
      if(t === choice.tag)
        r.score = ApplyWeights(choice.inputs, r.weights);
        // FOR ANALYZING ONLY, DUMPING SCORES INTO A NEW ARRAY TO LOOK AT HOW SCORES ARE BEING IMPACTED BY CHOICES
        const existingObjects = anaylticsArr.find(el => el.name === r.name)
        if (existingObjects) {
          existingObjects.scores.push(r.score);
        } else {
          anaylticsArr.push({
            name: r.name,
            scores: [r.score]
          });
        }
    })
    // RETURN OBJECT
    console.log(anaylticsArr);
    return r;
  })

  console.log(anaylticsArr);
  return result;
}
// HERE I AM ITERATING THROUGH THE INPUTS THROUGH THE weights ARRAY
const ApplyWeights = (inputs: R, weights: R): R => {
  if (inputs.length !== weights.length) {
    throw new Error("inputs & weights do not have the same length (it should be three!)");
  }
  // MULTIPLE THE NUMBER / WEIGHT MATCH IN THE ARRAY
  // ADD THE SUM TO THE RESULT
  // RETURN THE TOTAL WEIGHTED SUM OF ALL INPUT CALCULATIONS
  let sum = 0;
  for (let i = 0; i < inputs.length; i++) {
    sum += inputs[i] * weights[i];
  }
  console.log(sum);
  return sum;
}

// #endregion


  