import React, { FC } from 'react';
import styled from 'styled-components';

import { Shell } from '../../main/components';
import MainEditor from '../components/Editor';

/*

MAIN BUILDER PAGE FOR QUICK BUILDER / DOCUMENT BUILDER

*/

interface props {
    className?: string
};

const DocumentBuilderComp: FC<props> = ({ className }) => {


    return (
        <>
        <Shell className={className}>
            <section className="parent">
                <div className="container">
                    <MainEditor />
                </div>
            </section>
        </Shell>
        </>
    );
};

const DocumentBuilder = styled(DocumentBuilderComp)`
    
    .parent {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    min-height: 100vh;
}

.container {
    background-color: white;
    height: 100%;
    margin-top: 10em;
    border-radius: 10px;
}

`

export default DocumentBuilder;