import React, { createContext, useContext } from 'react';
import { useQPReducer } from './reducers';

import { Part as part } from '../../../lib';

/*

QUICK PARTS CONTEXT INITIALIZER
VALUES THAT EXIST WITHIN THE CONTEXT OF QPAPP.TSX SHOULD BE WRITTEN HERE

*/

export interface State {
    activeBom: string,
    activePlant: string,
    activeParts: part[],
    filteredParts: part[],
    activePart: part,
    activeDocs: string,
    activeWindow: number,
    activeFilters: string[]
    activeFile: string,
}

const QPContext = createContext<[State, React.Dispatch<any>]>([
    {
        activeBom: '',
        activePlant: '',
        activeParts: [],
        filteredParts: [],
        activePart: {
            id: '',
            MFG: {
                id: "mfg-1",
                name: "Manufacturer Inc.",
                manuals: [
                    { id: "manual-1", file: "manual.pdf" },
                    { id: "manual-2", file: "manual2.pdf" },
                ],
            },
            BOM: {
                id: "bom-1",
                name: "Bill of Materials",
                department: "Engineering",
                dateAdded: new Date(),
            },
            addDescriptiveElement(element, name) {},
            addFileType(fileList, name) {}
            },
        activeDocs: '',
        activeWindow: 0,
        activeFilters: [],
        activeFile: '',
    },
    () => {}
]);
const { Provider } = QPContext;

const QPProvider = ({ value = [], ...props }) => {
    const [state, dispatch] = useQPReducer({
        activeBom: '',
        activePlant: '',
        activeParts: [],
        filteredParts: [],
        activePart: {
            id: '',
            MFG: {
                id: "mfg-1",
                name: "Manufacturer Inc.",
                manuals: [
                    { id: "manual-1", file: "manual.pdf" },
                    { id: "manual-2", file: "manual2.pdf" },
                ],
            },
            BOM: {
                id: "bom-1",
                name: "Bill of Materials",
                department: "Engineering",
                dateAdded: new Date(),
            },
            addDescriptiveElement(element, name) {},
            addFileType(fileList, name) {}
        },
        activeDocs: '',
        activeWindow: 0,
        activeFilters: [],
        activeFile: '',
    });
    return <Provider value={[state, dispatch]} {...props} />
};

const useQPContext = () => {
    return useContext(QPContext);
};

export { QPProvider, useQPContext };