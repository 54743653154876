import React, { FC } from 'react';
import styled from 'styled-components';
import Folders from '../components/Folders';
import Activity from '../components/Activity';
import Container from '../layouts/Container';

interface props {
    className?: string
}

const MediaLibraryComp:FC <props> = ({ className }) => {
    return (
        <Container className={className}>
                    <Folders />

                    <Activity>

                    </Activity>
        </Container>
    )
};

const MediaLibrary = styled(MediaLibraryComp)`
    
    .parent {
            background: rgb(64,48,54);
            background: linear-gradient(175deg, rgba(64,48,54,1) 64%, rgba(158,0,93,1) 98%);
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
            background-position: top;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            justify-content: inherit;
            width: 100%;

        & .body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 75vh;
            width: 80%;
            margin-left: 30vh;
        }

        & .search {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 7vh;
            display: flex;
            align-content: center;
        }

        & .logo {
            width: 3em;
            height: 3em;
            margin-left: 5px;
            transition: 
                transform 1s,
                background-color 1s,
                mix-blend-mode 1s;
        }

        & .logo:hover {
            transform: scale(1.01);
            color: aliceblue;
            mix-blend-mode: lighten;
        }
    }

`

export default MediaLibrary;