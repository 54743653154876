import { Lang, Dict } from "./Dict"

export const FR: Dict = new Lang(
    "FR",
    "bienvenue",
    "documentation",
    "nouvelles alertes EWO",
    "télécharger un",
    "outil",
    "nombre",
    "numéro d'outil",
    "usine",
    "manuel",
    "machine",
    "extension",
    "vidéos",
    "sélectionné",
    "rechercher un outil",
)