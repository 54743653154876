import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

/*

YET ANOTHER SUB BUTTON TYPE, THIS WILL NEED TO BE CLEANED UP, ALONG WITH THE OTHER BUTTONS
...SORRY!

- ZB

*/

interface props {
	className?: string,
	title: string,
	img: string,
	icon: ReactNode,
	desc:  string,
	link: string
}


const HomeButtonComp: FC<props> = ({ className, title, img, icon, desc, link }) => {
	return (
		<a href={link} style={{ textDecoration: 'none' }}>
			<button className={className}>
				{icon && icon}
				{img && <img alt="button icon" src={img} />}
				<h3>
					{title}
					<br />
					<p className="btn-desc">{desc}</p>
				</h3>
			</button>
		</a>
	);
};

const HomeButton = styled(HomeButtonComp)`
	z-index: 4;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: end;
	width: 24vw;
	height: fit-content;
	padding: 0;
	border-radius: 1rem;
	transition: all 0.3s ease-in-out;
	background: #000;
	margin: 20px;

	& svg {
		color: #fff;
		display: block;
		margin: auto;
		width: 22vw;
		height: 24vh;
		padding: 20px;
	}

	.btn-desc {
		font-size: 22px;
		font-weight: 400;
		margin: 0;
	}

	& h3 {
		margin: 0 auto;
		background: white;
		width: 100%;
		padding: 10px;
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
		color: red;
		font-weight: bolder;
		font-size: 30px;
	}

	& img {
		width: 100%;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		padding: 40px;
	}

	& svg {
		width: 100%;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		padding: 40px;
		color: #fff;
	}

	&:hover {
		transform: scale(1.01);
	}

	@media screen and (max-width: 480px) {
		width: 80vw;
		margin: 20px auto;
	}
`;

export default HomeButton;
