import React, { FC, useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { SET_PART, useQPContext, useGlobalContext } from '../../../../utils';

import { P as Part } from '../../../../lib';

import { 
    FaWrench, 
    FaFilePdf, 
    FaExclamationTriangle, 
    FaVideo, 
    FaGlasses,
    FaReadme,
 } from 'react-icons/fa';

 interface props {
    className?: string,
    arr: any[],
    displayName?: string,
    icon: ReactNode
 }

const TableComp: FC<props> = ({ className, arr, displayName, icon }) => {

    const [state, dispatch] = useQPContext();
    const [globalState] = useGlobalContext();
    const { activePart } = state;
    const { activeLang } = globalState;

    const [part, setPart] = useState({});

    const handleClick = (el: Part, index: number) => { 
        setPart(el)
    }
    
    useEffect(() => {
        dispatch({
            type: SET_PART,
            activePart: part
        })
        console.log(activePart);
    },[part, dispatch, activePart])

    const getFilter = (): Part[] => {
        let x = arr.filter(el => {
            return el.partNumber !== "";
        })
        x = x.filter(el => {
            return el.partDescription !== "";
        })
        console.log(x);
        return x;
    }

    return (
        <section className={className}>
            <section className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>{activeLang.partNumber}</th>
                            <th>MFG</th>
                            <th><FaWrench size={20} /></th>
                            <th><FaExclamationTriangle size={20} /></th>
                            <th><FaFilePdf size={20} /></th>
                            <th><FaGlasses size={20} /></th>
                            <th><FaVideo size={20} /></th>
                            <th><FaReadme size={20} /></th>

                        </tr>
                    </thead>
                    <tbody>
                        {getFilter().slice(3).map((el: Part, i: number) => {
                            return (
                                <tr className={`${el === part && 'selected'}`} key={i} onClick={(e) => handleClick(el, i)}>
                                    {/* <td>{el.partNumber ? el.partNumber : "NO PART NUMBER!"}</td>
                                    <td>{el.oem.name ? el.oem.name : "NO MFG"}</td>
                                    <td>{el.opl.length > 0  ? <FaCircle size={20} color="forestgreen" /> : <FaExclamationCircle size={20} color="salmon" />}</td>
                                    <td>{el.ewo.length > 0  ? <FaCircle size={20} color="#D0342C" /> : <FaExclamationCircle size={20} color="salmon" />}</td>
                                    <td>{el.smp.length > 0 ? <FaCircle size={20} color="teal" /> : <FaExclamationCircle size={20} color="salmon" />}</td>
                                    <td>{el.oem.manuals.length > 0 ? <FaCircle size={20} color="orange" /> : <FaExclamationCircle size={20} color="salmon" />}</td>
                                    <td>{el.video.length > 0  ? <FaCircle size={20} color="powderblue" /> : <FaExclamationCircle size={20} color="salmon" />}</td>
                                    <td>{el.sop.length > 0  ? <FaCircle size={20} color="blueviolet" /> : <FaExclamationCircle size={20} color="salmon" />}</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </section>
        </section>
    );
}

const Table = styled(TableComp)`

.table-container {
    overflow-y: auto;
    border-radius: 20px;
    min-height: 20em;
    max-height: 40em;
    padding: 1em;
}

table {
    color: white;
}

tbody tr:nth-child(odd) {
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.6), rgba(255,255,255,0));
}

tbody tr:hover {
    background-color: blue;
    opacity: 80%;
    cursor: pointer;
    font-style: italic;
}

tbody .selected {
    background-color: blue;
}

tbody .notselected:nth-child(odd) {
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.6), rgba(255,255,255,0));
}

td {
    padding: 3px;
}

th {
    padding: 3px;
}
`

export default Table;