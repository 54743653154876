import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdCancel } from 'react-icons/md';
import { BOM } from '../../lib';

/*

 A DELETE BUTTON CURRENTLY BEING USED AS TO DELETE BILL OF MATERIALS FROM QP DB
 COULD BE MERGE WITH OTHER DELETE BUTTONS USING CONTEXT

 - ZB

*/


interface props {
    className?: string
}

const DeleteBOMComp:FC <props> = ({ className }) => {
    const [boms, setBoms] = useState<BOM[]>([]);

    useEffect(() => { fetchBoms() },[]);

    const fetchBoms = async () => {
        const res = await fetch('/api/BOM');
        const data = await res.json();
        console.log(data);

        setBoms(data);
    }

    const handleDelete = async (id: string) => {
        console.log(id);
        try {
            const res = await fetch(`/api/BOM/${id}`,
            {
                method: 'DELETE',
                body: id
            });
            if (res.ok) {
                console.log(res);
            } else {
                console.log(res);
            }
        }
        catch (err) {
                console.log(err)
        }
    }

    return(
        <>
            <div className={className}>
                <section className="container">
                {boms && boms.map((el, i) => {
                    return (
                        <section className="element-container" key={i}>
                        <div className="element" key={i}>
                            {el.name}
                        </div>
                        <div onClick={(e) => handleDelete(el.id)}> <MdCancel size={25} /> </div>
                        </section>
                    );
                })}
                </section>
            </div>
        </>
    );
}

const DeleteBOM = styled(DeleteBOMComp)`
    
    .container {
        /* display: flex; */
        background-color: antiquewhite;
        border-radius: 10px;
    }

    .element-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        margin-top: 1em;
        margin-bottom: 1em;
        
    }

    svg {
        margin-left: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    svg:hover {
        transform: scale(1.1);
        color: #376f84;
    }

    .element {
        margin-top: 1em;
        margin-bottom: 1em;
        text-align: center;
    }

`

export default DeleteBOM;