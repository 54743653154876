import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import Node from './Node';
import { gridElement } from './element';
import { ADD_EDITOR, UPDATE_GRID, useQBContext } from '../../../utils';
import { Empty } from '../../../resources';

/* 

MAIN GRID COMPONENT THAT HOLDS EDITABLE NODES FOR MAIN EDITOR

GRID NEEDS TO BE EITHER VERY CONDITIONAL OR HARD LIMITED,
UNSURE OF WHICH WOULD WORK BETTER LONG TERM AND FOR USABILITY

- ZB

*/

interface props {
    className?: string
}

const grid: gridElement[] = 
             [{ index: 0 }, { index: 1 }, { index: 2 }, { index: 3 }, { index: 4 },
              { index: 5 }, { index: 6 }, { index: 7 }, { index: 8 }, { index: 9 },
              { index: 10 }, { index: 11 }, { index: 12 }, { index: 13 }, { index: 14 },
              { index: 15 }, { index: 16 }, { index: 17 }, { index: 18 }, { index: 19 },
              { index: 20 }, { index: 21 }, { index: 22 }, { index: 23 }, { index: 24 }]


const NodesComp: FC<props> = ({ className }) => {
    const [state, dispatch] = useQBContext();
    const { activeEditors, activeGrid } = state;
    const [currentGrid] = useState(grid);

    useEffect(() => {
        // updateGrid(findAndUpdate)
        
        dispatch({
            type: UPDATE_GRID,
            activeGrid: currentGrid
        })
        console.log(activeGrid)
    },[activeEditors, activeGrid, dispatch, currentGrid])

    // const findAndUpdate = () => {
    //     activeEditors.forEach((n, i) => {
    //         let node: ReactNode = n;
    //         let found: number = currentGrid.findIndex(x => x.index == i)
    //         currentGrid[found].el = node;
    //         return currentGrid;
    //     })
    //     return currentGrid;
    // }

    useEffect(() => {
        for (let i = 0; i < grid.length; i++) {
            dispatch({
                type: ADD_EDITOR,
                activeEditors: <Empty />
            })
        }
    },[dispatch])

    return(
        <div className={className}>
            <section className="grid">
            {activeGrid.map((x, i) => {
                return <Node key={i} index={x.index} node={activeEditors[i]} />
            })}
            </section>
        </div>
    )
};

const Nodes = styled(NodesComp)`



.grid {
    background-color: lightgrey;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

`

export default Nodes;