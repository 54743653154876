import { FC } from 'react';
import styled from 'styled-components';
import Container from '../../layouts/Container';
import { PROFILE } from '../../../assets';
import { ProfileIcon } from '../../components/Dashboard/Tools';
import { FaBookmark, FaWrench, FaPenFancy } from 'react-icons/fa';
import { AiFillBulb } from 'react-icons/ai';

const ProfileComp:FC <IBaseGlobalProps> = ({ className }) => {
    return (
        <Container className={className}>
            <div className='parent'>
                <div className="profile-card">
                    <ProfileIcon icon={PROFILE}></ProfileIcon>
                    <button>
                        Saved Posts
                        <FaBookmark size={30} />
                    </button>
                    <button>
                        Edit Profile
                        <FaWrench size={30} />
                    </button>
                    <button>
                        My Contributions
                        <FaPenFancy size={30} />
                    </button>
                </div>

                <div className="activity">
                    <h1>Mentions <AiFillBulb size={30} color={"darkorange"} /></h1>
                    <p>No one has mentioned you yet...</p>
                </div>
            </div>
        </Container>
    )
};

const Profile = styled(ProfileComp)`
    .parent {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        min-height: 100%;
        align-items: center;
        flex-direction: column;
    }

    .profile-card {
        background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0), rgba(59, 59, 59, 0.4));
        backdrop-filter: blur(10px); 
        box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
        min-height: 10em;
        min-width: 100%;
        background-color: #242424;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;

        & button {
            border: none;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(14, 14, 14, 0.6));
            border-radius: 10px;
            min-width: 70%;
            min-height: 10vh;
            margin-top: .5em;
            margin-bottom: 1em;
            font-weight: 700;
            text-transform: uppercase;
            color: antiquewhite;
            box-shadow: .2em .2em #242424;
        }

        svg {
            margin-left: .5em;
        }
    }

    .activity {
        margin-top: 1em;
        text-align: center;
        color: white;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(14, 14, 14, 0.6));
        backdrop-filter: blur(10px); 
        box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
        min-width: 100%;
        border-radius: 10px;
        text-shadow: .1em .2em #242424;

        & p {
            font-style: italic;
        }
    }


`

export default Profile;