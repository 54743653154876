import React, { FC } from 'react';
import styled from 'styled-components';
import { LoginForm } from '../../../resources';
import Container from '../../layouts/Container';
/*

LOGIN PAGE

- ZB

*/


interface props {
    className?: string,
}

const LoginComp: FC<props> = ({ className }) => {
    return (
            <Container className={className}>
                <div className="body">
                    <LoginForm />
                </div>
            </Container>
    );
}

const Login = styled(LoginComp)`
    
    .body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;
    align-items: center;
}

`

export default Login;