import { FC, useState } from 'react';
import styled from 'styled-components';
import { stationList } from '../../lib/BookLists';
import { Station } from '../../lib/book';
import { FaArrowAltCircleDown, FaArrowAltCircleRight } from 'react-icons/fa';

const StationsComp:FC <IBaseGlobalProps> = ({ className }) => {

    const [element, activeElement] = useState<Station>();
    const [stationView, setStationView] = useState<boolean>(false);

    const handleStationClick = (el: Station) => {
        activeElement(el);
        setStationView(true);

    }

    const stationContent = [
        'Block 1',
        'Block 2',
        'Block 3',
        'Block 4',
        'Block 5',
        'Block 6',
        'Block 7',
    ]

    return (
        <div className={className}>
            <div className="context-container">
                <h3>Department 0000</h3>
                <FaArrowAltCircleRight size={40}/>
                <h3>Team 1</h3>
                <FaArrowAltCircleDown size={40} />
            </div>
            <div className="station-container">
            {!stationView && stationList.map((el, i) => {
                return (
                    <div className='station' key={i} onClick={() => handleStationClick(el)}>
                        <h3>{el.name}</h3>
                        <h3>contributors: {el.contributors?.length}</h3>
                        <h3>blocks: {el.posts.length}</h3>
                        <FaArrowAltCircleDown size={40} />
                    </div>
                )
            })}
            {stationView && element &&
                <div className="container">
                    <h3 className="centered-name">{element.name}</h3>
                    <div className="content-container">
                        {stationContent.map((el, i) => {
                            return (
                                <div className="content-element">
                                        <h4>{el}</h4>
                                        <div className="blob-content">
                                            <p>Content</p>
                                        </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            </div>
        </div>
    )
};

const Stations = styled(StationsComp)`

    height: 35vw;

    .context-container {
        background-color: aliceblue;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & h3 {
            background-color: #252525;
            padding: .5em;
            border-radius: 10px;
            color: aliceblue;
        }
    }

    .station-container {
        width: 50vw;
        height: 100%;
        background-color: antiquewhite;
        border-radius: 10px;
        margin-top: 1em;
        overflow-y: auto;


        & .container {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            & h3 {
                margin-top: .5em;
                text-align: center;
                background-color: #252525;
                color: antiquewhite;
                width: 10vw;
                height: 2vw;
                border-radius: 10px;
            }


        }

    }

    .station {
        display: flex;
        margin-top: 1em;
        margin-bottom: 1em;
        background-color: antiquewhite;
        min-width: 50vw; 
        min-height: 5em;
        align-items: center;
        border-radius: 10px;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;
    }

    .station:hover {
        background-color: aliceblue;
        transform: scale(1.01);
    }

    .content-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        border: 1px solid green;
        padding: .5em;
    }

    .content-element {
        margin: .5em;
        width: 20vw;
        height: 35vh;
        border: 1px red solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        & .blob-content {
                border: blue solid 1px;
                height: 80%;
                width: 95%;
                text-align: center;
                align-self: center;

                & p {
                    margin-top: 25%;
                    font-style: oblique;
                }
                
            }
        & h4 {
            text-align: center;
        }
    }

    .content-element:hover {
        transform: scale(1.02);
        background-color: aliceblue;
    }
    
`

export default Stations;