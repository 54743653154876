import { forwardRef, PropsWithChildren, Ref, FC } from 'react'
import styled from 'styled-components'


interface props {
    className?: string,
    icon?: string,
    text?: string
}

export const TitleCard = forwardRef(
    (
        { className, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        />
    )
);

export const GreetingWindow = forwardRef(
    (
        { className, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
            {...props}
            ref={ref}
            className={className}
        />
    )
);

const WelcomeComp:FC <props> = ({ className, text }) => {
    return (
        <GreetingWindow className={className}>
            <h1>Welcome, <span>{text}</span></h1>
        </GreetingWindow>
    )
}

export const Welcome = styled(WelcomeComp)`

margin: 0 auto;
    
h1 {
    color: antiquewhite;
    font-weight: 700;
    font-style: italic;
    text-align: center;
}

span {
    color: darkorange;
}

`

const BannerComp:FC <props> = ({ className, icon }) => {
    return (
        <TitleCard className={className}>
            <img alt="Quick Suite Logo" src={icon} />
            <h3>Your Applications</h3>
        </TitleCard>
    )
};

export const Banner = styled(BannerComp)`

display: flex;
flex-direction: column;
align-items: center;

img {
    width: 100%;
    padding: .5em;
}

h3 {
    color: white;
    text-align: center;
    font-style: oblique;
}

`

const ProfileIconComp:FC <props> = ({ className, icon }) => {
    return (
        <div className={className} style={{ backgroundImage: `url(${icon})` }}>
            <h3>Hello, FABTECH</h3>
        </div>
    )
};

export const ProfileIcon = styled(ProfileIconComp)`
        display: flex;
        background-color: red;
        background-size: cover;
        min-width: 50vw;
        max-width: 50vw;
        max-height: 25vh;
        min-height: 25vh;
        /* border: 2px #242424 solid; */
        border-radius: 50%;
        display: flex;
        margin-top: .5em;
        margin-bottom: 1em;
        box-shadow: 1em .5em darkorange;
        white-space: nowrap;

        & h3 {
            color: white;
            font-weight: 600;
            text-shadow: 2px 2px #242424;
            
        }

`

