import React, { FC } from 'react';
import styled from 'styled-components';

/*

GENERAL DROP DOWN TAB CODE
CURRENTLY BEING USED TO HANDLE QUICK PARTS SORTING --- TECHINCALLY!!!

- ZB

*/

interface props {
    className?: string,
    names?: string[],
    placeholder?: string,
    tag?: string
}

const DropComp: FC<props> = ({ className, names, placeholder, tag }) => {


    return (
        <>
            <section className={className} >
                <div className="Drop-container">
                    <select placeholder={placeholder}>
                        <option>{tag}</option>
                        {names && names.map((n: string, index: number) => {
                            return <option key={index}>{n}</option>
                        })}
                    </select>
                </div>
            </section>
        </>
    );
};

const Drop = styled(DropComp)`

Drop-container {
    width: 100%;
    height: 100%;
}

select {
    list-style: none;
    text-decoration: none;
    width: 18em;
    height: 3em;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
    background-color: #fff;
    font-size: 1em;
    color: #36454f;
    border: none;
    border-radius: 10px;
    margin-left: 1em;
}
`

export default Drop;