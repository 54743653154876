import React, { FC } from 'react';
import styled from 'styled-components';
import { Spinner } from '../../resources';
import Feed from '../components/Feed';
import Container from '../layouts/Container';

interface props {
    className?: string
}

const HomeComp:FC <props> = ({ className }) => {
    return (
        <>
            <section className={className}>
                <Container>


                    <section className="feed-container">
                        <Feed />

                        <div className="spinner">
                            <Spinner />
                        </div>
                    </section>

                </Container>
            </section>
        </>
    )
}

export const Home = styled(HomeComp)`
    

    .feed-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .spinner {
            margin-bottom: 2em;
            margin-top: 2em;
        }
    }

`