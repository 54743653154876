import React, { FC } from 'react'
import isUrl from 'is-url'

import styled from 'styled-components';
import { Editor } from 'slate'
import { Transforms, Descendant, Element as SlateElement } from 'slate'
import {
  Slate,
  Editable,
  useSelected,
  useFocused,
} from 'slate-react'

import imageExtensions from '../../../lib/imageExtensions.json';
import { DeleteButton } from '../Buttons';

/*

IMAGE COMPONENT AND HELPER COMPONENTS
THIS HANDLES IMAGE RENDERING

- ZB

*/

// interface ImageProps {
//     className?: string
//     attributes?: SlateElement
//     children: ReactNode
//     element: SlateElement
// }

interface ElementProps { className?: string; editor: Editor, index: number }

// IMAGE ELEMENT THAT IS INSERTED INTO DOCS

export const ImageElement = (props: any) => {
    const { attributes, children, element } = props
    console.log(props)
    switch (element.type)
    {
        case 'image':
            return <Image attributes={attributes} element={element} children={children} />
        default:
            return <p {...attributes}>{children}</p>
    }
}

// IMAGE CONTENT

const ImageComp = (props: any) => {

    const selected = useSelected();
    const focused = useFocused();
    console.log(props.element)
    return (
        <>
        <div
        {...props.attributes}
        className={props.className}
        >
            {props.children}
                <img
                alt=""
                style={{
                    display: "flex",
                    maxWidth: "100%",
                    height: "30em",
                    boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`
                }}
                src={props.elementurl ? props.element.url?.toString() : 'https://i.ibb.co/ccKNRgW/2.jpg'}
                />

        </div>
        </>
    );
}

const Image = styled(ImageComp)`

`

const EditImageComp: FC<ElementProps> = (props) => {
    return (
        <>
            <div className={props.className}>
                <DeleteButton editor={props.editor} isImage={true} index={props.index} />
                <Slate editor={props.editor} initialValue={initialValue}>
                    <Editable
                    renderElement={props => <Image {...props} />}
                    placeholder='Enter text'
                    autoFocus
                    />
                </Slate>
            </div>
        </>
    )
}

const EditImage = styled(EditImageComp)`
    
    display: flex;
    width: 10em;
    height: 10em;
`

const withImage = (editor: Editor) => {
    console.log('calede')
    const { insertData } = editor;
    // editor.isVoid = el => {
    //     console.log(isVoid(el))
    //     return el.type === 'image' ? true : isVoid(el)
    // };

    editor.insertData = data => {
        console.log(data)
        const text = data.getData('text/plain');
        const { files } = data;

        if (files && files.length > 0) {
            console.log(files)
            for (const file of files) {
                const reader = new FileReader();
                const [mime] = file.type.split('/');

                if (mime === 'image') {
                    reader.addEventListener('load', () => {
                        const url: string | ArrayBuffer | null = reader.result;
                        insertImage(editor, url);
                    })

                    reader.readAsDataURL(file);
                }
            }
        } else if (isImageURL(text)) {
            console.log(text)
            insertImage(editor, text);
        } else {
            console.log(data)
            insertData(data);
        }
    }

    return editor;
}

// CHECK IF URL IS AN IMAGE URL

const isImageURL = (url: string) => {
    if (!url) return false
    if (!isUrl(url)) return false
    const ext: any = new URL(url).pathname.split('.').pop()
    return imageExtensions.includes(ext);
}

// METHOD FOR ADDING IMAGES TO ELEMENT

const insertImage = (editor : Editor, url: string | ArrayBuffer | null) => {
    console.log(url);

    const text = { text: '' }
    const image: SlateElement = { type: 'image', url, children: [text] }
    console.log(image)
    Transforms.insertNodes(editor, image)
  }

const initialValue: Descendant[] = []


export { EditImage, insertImage, isImageURL, withImage }