import { useReducer } from "react";
import { State } from "./GlobalState";

import {
    SET_LANGUAGE
} from './actions';

export const reducer = (state: State, action: any) => {
    switch (action.type)
    {
        case SET_LANGUAGE:
            return {
                ...state,
                activeLang: action.activeLang
            }
        default:
            return state;
    }
};


export function useAppReducer(initialState: State) {
    return useReducer(reducer, initialState);
}