import { FC, ReactNode } from 'react';
import Main from '../Main';
import MainBody from './MainBody';
import ContentNav from '../ContentNav';
import { Header } from '../../';
import Footer from '../../Footer';

interface ShellProps {
	className?: string;
	children: ReactNode;
	nav?: string[];
}

const MainShell: FC<ShellProps> = ({ className, children, nav }) => {
	return (
		<>
			<Main className={className}>
				<Header />
				{nav && (
					<ContentNav
					/>
				)}
				<MainBody>{children}</MainBody>
				<Footer />
			</Main>
		</>
	);
};

export { MainBody, Main, ContentNav, MainShell };