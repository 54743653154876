import { FC, ReactNode } from "react"
import { ReactEditor } from 'slate-react'
import {
  Editor,
  Transforms,
  Node,
  NodeEntry,
  Element as SlateElement,
} from 'slate'

import BuilderButton from "./BuilderButton"

/*

BUTTON(S) THAT HANDLES ADJUSTING TEXT ALIGNMENT AND FORMATTING INSIDE A TEXT EDITOR
PART OF THE MAIN EDITOR CONTROL PANEL / EDIT BAR

- ZB

*/

interface props {
    format: string,
    icon: ReactNode,
    editor: ReactEditor
}

const listTypes = ['numbered-list', 'bulleted-list']
const textAlignTypes = ['left', 'center', 'right', 'justify']

const BlockButton: FC<props> = ({ format, icon, editor }) => {
    const isBlockActive = (editor: any, format: string, blockType = 'type') => {
        const { selection } = editor;
        if (!selection) { return false; }
        const [match]: NodeEntry<Node>[] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: (n: Node) =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n['type'] === format,
            })
        )
        return !!match
    }

    const toggleBlock = (editor: any, format: string) => {
        const isActive = isBlockActive(
            editor,
            format,
            textAlignTypes.includes(format) ? 'align' : 'type'
          )
          console.log(isActive);
          const isList: boolean = listTypes.includes(format)
        
          Transforms.unwrapNodes(editor, {
            match: (n: Node) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              listTypes.includes(n.type) &&
              !textAlignTypes.includes(format),
              split: true,
          })
          let newProperties: any
          if (textAlignTypes.includes(format)) {
            newProperties = {
              align: isActive ? undefined : format,
            }
          } else {
            newProperties = {
              type: isActive ? 'paragraph' : isList ? 'list-item' : format,
            }
          }
          Transforms.setNodes<SlateElement>(editor, newProperties)
        
          if (!isActive && isList) {
            const block: any = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
          }
    }


    return (
      <div 
      onMouseDown={e => {
      e.preventDefault()
      toggleBlock(editor, format)
      }}>

      <BuilderButton
        active={isBlockActive(
          editor,
          format,
          textAlignTypes.includes(format) ? 'align' : 'type'
        )}
        icon={icon}
      />
      </div>
    )
  }

export default BlockButton;