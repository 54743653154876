import React, { Route, Routes } from 'react-router-dom';

import Login from "../main/pages/Login";
import { NavigationPage } from '../main/pages';
import Tree from '../QuickDecider/pages/Tree';
import Annotations from '../resources/models/Annotations';
import Profile from '../main/pages/Profile';

const GlobalRouter = () => {
	return (
		<Routes>
			<Route path="/" element={<Login />} />
			<Route path="/nav" element={<NavigationPage />} />
			<Route path="/decider" element={<Tree />} />
			<Route path="/test" element={<Annotations />} />
			<Route path="/profile" element={<Profile />} />
		</Routes>
	);
};

export default GlobalRouter;
