import { Lang, Dict } from "./Dict"

export const RU: Dict = new Lang(
    "RU",
    "Привет",
    "документация",
    "Новые оповещения EWO",
    "Загрузить",
    "инструмент",
    "число",
    "Номер инструмента",
    "фабрика",
    "manual",
    "машина",
    "расширение",
    "клипов",
    "выбранного",
    "Поиск инструмента",
)