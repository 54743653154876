import { ReactNode } from "react";
import { Lang } from "../translations";

export enum EPost {
    Update,
    Image,
    Document,
    Video,
    Model,
    Annotations,
}

export type App = {
    name: string,
    flavor?: string,
    icon?: string,
    element?: ReactNode,
    link: string,
    active: boolean,
};

export type PostElement = {
    blobName: string,
    bodyText?: string,
    notes: CommentElement[],
    createdAt: string,
    postType?: EPost,
    file?: string,
    src?: string
}

export type CommentElement = {
    id: number,
    user: User,
    body: string,
    dateCreated: Date | string
}

export type User = {
    id: number,
    username: string,
    company: string,
    accountLevel: "USER" | "ADMIN" | "EDITOR",
    savedPosts?: PostElement[],
    comments?: CommentElement[],
    language?: Lang
}

export type NavItem = {
    name: string
    image: ReactNode
    link: string
}

