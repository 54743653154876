import React, { FC } from 'react';
import styled from 'styled-components';
import { appList } from '../../../lib/AppList';

// COMPONENT IMPORTS
import { AppList, Card } from '../../components';
import Container from '../../layouts/Container';

/*

MASTER APP NAVIGATION PAGE
UI FOR MAIN APP ROUTER DIRECTING TO SUB APPS

- ZB

*/

interface props {
    className?: string
}

const NavigationPageComp:FC <props> = ({ className }) => {
    return (
        <>
            <Container className={className}>
                <div className="parent">
                    <AppList apps={appList}  />
                    <Card />
                </div>
            </Container>
        </>
    );
};

const NavigationPage = styled(NavigationPageComp)`

    .parent {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
    }

    @media screen and (max-width: 480px) {
        .parent {
            flex-direction: column;
            flex-flow: column-reverse;
        }
    }
`

export default NavigationPage;