import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { TRAINING } from '../../assets';
import VideoDisplay from '../components/VideoPlayer';
import Container from '../layouts/Container';

interface props {
    className?: string
}

const TrainingLibraryComp:FC <props> = ({ className }) => {

    const [selectedVideo, setSelectedVideo] = useState('');
    const [isTransparent] = useState('transparent');

    // const updateBackground = (name: string) => {
    //     if (isTransparent == 'transparent' && name !== selectedVideo)
    //     {
    //         setTransparent('');
    //     } else {
    //         setTransparent('transparent');
    //     }
    //     console.log(isTransparent);
    // }

    const handleUpdate = (name: string) => {
        setSelectedVideo(name)
    }

    const arr: string[] = [
        'EXAMPLE 1',
        'EXAMPLE 2',
        'EXAMPLE 3',
        'EXAMPLE 4',
        'EXAMPLE 5',
        'EXAMPLE 6',
    ]


    return (
        <>
        <Container className={className}>
            <div className="video-container">
                    <section className="video-display">
                        <VideoDisplay 
                        video={selectedVideo}
                        />
                    </section>
                    <section className="training-library">
                        <h1>Video Library</h1>
                        <p>scroll & tap to select...</p>
                        
                        {arr.map((video: string) => {
                            return (
                                <div  style={{backgroundColor: `${isTransparent}`}}>
                                <div className="video"
                                onClick={(e) => 
                                    handleUpdate(video)}>

                                        <img alt="" src={TRAINING} />
                                        <h1>{video}</h1>
                                </div>
                                </div>
                            );
                        })}
                    </section>
            </div>
        </Container>
        </>
    )
};

const TrainingLibrary = styled(TrainingLibraryComp)`



.video-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-around;
}

.dimmer {
    width: 100%;
    height: 100%;
    z-index: 20;
    position: fixed;
    background-color: black;
    opacity: 70%;
}

.btn {
    background-color: var(--secondary);
    color: #fff;
    margin-right: 1rem;
    margin-top: .4rem;
}

.training-library {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 20%;
    max-height: 43.5rem;
    border-radius: 1rem;
    overflow-y: auto;
    box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);

    & h1 {
        margin-top: 2rem;
        width: 100%;
        color: #fff;
        text-align: center;
        background-color: #231f20;
        font-weight: bold;
        margin-bottom: 0;
    }

    & p {
        width: 100%;
        color: #fff;
        text-align: center;
        background-color: #231f20;
        font-style: italic;
    }
}

.active {
    background-color: var(--secondary);
    border-radius: 1rem;
    background-position: left;
}

.video {
    position: relative;
    margin: auto;
    margin-top: 50px;
    height: 12rem;
    width: 20em;
    border-radius: 1rem;
    border-top-right-radius: 0;
    box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);

    & h1 {
        position: relative;
        width: 100%;
        color: #fff;
        text-align: center;
        background-color: #231f20;
        padding: 5px;
        font-weight: bold;
        font-size: medium;
        bottom: 60%;
    }

    &:hover h1 {
        background-color: var(--secondary);
    }

    & a {
        text-decoration: none;
        display: block;
        height: 100%;
    }

    & img {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        border-top-right-radius: 0;
    }

    & svg {
        z-index: 10;
        position: absolute;
        /* top: 20%;
        left: 38%; */
        color: #fff;
        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
        margin-bottom: 2.5vw;

        &:hover {
            color: var(--secondary);
        }
    }

    &:hover {
        transform: scale(1.01);
    }
}

.video-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;
}

@media screen and (max-width: 480px) {

    .tooltip-help {
        display: none;
    }

    .content-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .training-library {
        position: relative;
        width: 90%;
        overflow-y: auto;
    }

    .video {
        width: 90%;
        height: 20vh;
        margin: 0 auto;
        margin-bottom: 30px;
        margin-top: 15px;
    }

    .video-overlay svg {
        width: 24vw;
    }

    .video-nav {
        margin-top: .5rem;
        margin-right: 0rem;
        padding: 10px;
    }

    a & .btn {
        display: flex;
        margin: auto;
        justify-content: center;
        flex-wrap: wrap;
    }

    .search-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        & svg {
            margin: auto 10px;
            color: white;
        }

        & input {
            width: 100%;
            border-radius: .5rem;
            margin: 0;
            padding: 0px;
        }

    }
}


`

export default TrainingLibrary;