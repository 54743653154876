import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Shell } from '../../main/components';

interface props {
    className?: string
    children: ReactNode
}

const ContainerComp:FC <props> = ({ className, children }) => {
    return (
        <Shell className={className}>
            <div className="layout">
                    {/* CONTENT  */}
                    {children}
            </div>
        </Shell>
    )
};

const Container = styled(ContainerComp)`
    background: rgb(2,0,36);
    background: linear-gradient(170deg, rgba(2,0,36,1) 0%, rgba(14,14,66,1) 35%, rgba(80,43,55,1) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    min-height: 100vh;

`

export default Container;