import { Lang, Dict } from "./Dict"

export const SP: Dict = new Lang(
    "SP",
    "Hola",
    "documentación",
    "nuevas alertas EWO",
    "subir",
    "herramienta",
    "número",
    "número de herramienta",
    "fábrica",
    "manual",
    "máquina",
    "extensión",
    "videos",
    "seleccionado",
    "buscar una herramienta",
)