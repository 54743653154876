import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Shell } from '../../main/components';
import { Search } from '../../resources';
import { Services } from '../../QRL/components/Tools';
import { FaBookOpen } from 'react-icons/fa';
import { BookNav } from '../../lib/NavLists';

interface props {
    className?: string
    children: ReactNode
}

const ContainerComp:FC <props> = ({ className, children }) => {
    return (
        <Shell className={className}>
            <div className="layout">

                    {/* NAV PANEL */}

                    {/* 
                        color prop is passed under context of var(${color})
                        colors passed are global styles
                    */}
                    
                    <Services color={'--qbk-blue'} array={BookNav} />

                    <section className="body">

                    {/* SEARCH AND LOGO */}

                        <div className="task-bar">
                            <Search />
                            <FaBookOpen className='logo' color='#666396' />
                        </div>

                    {/* CONTENT  */}

                        {children}

                    </section>
            </div>
        </Shell>
    )
};

const Container = styled(ContainerComp)`

    /* background: rgb(64,48,54); */
    background: linear-gradient(175deg, #20212d 64%, #565297 98%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    min-height: 100vh;


    & .body {
        display: flex;
        /* justify-content: center; */
        justify-content: inherit;
        align-items: center;
        flex-direction: column;
        height: 100%;
        left: var(--navbar-width);
        margin-left: 2em;

    }


    & .task-bar {
        margin-top: 1em;
        display: flex;
        position: relative;
        z-index: 10;


        & .logo {
        width: 3em;
        height: 3em;
        margin-left: 5px;

        }

        & .logo:hover {
            transform: scale(1.01);
            mix-blend-mode: lighten;
            cursor: pointer;
        }

        & h3 {
            color: antiquewhite;
            font-weight: 700;
        }

        input {
            width: 20em;
        }
    }

    @media screen and (max-width: 1024px) {

        .body {
            margin: auto;
            left: 0;
            width: 100%;
        }

        .task-bar {
            & input {
                width: 20em;
            }
        }

    }

    @media screen and (max-width: 768px) {

        .body {
            margin: auto;
            left: 0;
            width: 100%;
        }
    }

    @media screen and (max-width: 480px) {
        min-height: 100%;

        .body {
            margin: auto;
            left: 0;
            width: 100%;
        }

        .task-bar {
            z-index: 10;
            & input {
                width: 70vw;
            }

            & img {
                display: none;
            }
        }


    }
    
`

export default Container;