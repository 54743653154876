import React, { Route, Routes } from 'react-router-dom';
import { FC, ReactNode } from 'react';
import { Home } from '../QRL/pages/Home';
import Documentation from '../QRL/pages/Documentation';
import TrainingLibrary from '../QRL/pages/TrainingLibrary';
import MediaLibrary from '../QRL/pages/MediaLibrary';
import Models from '../QRL/pages/3DModels';
import Procedures from '../QRL/pages/Procedures';
import ExternalContent from '../QRL/pages/ExternalContent';

/*

QUICK RESPONSE LIBRARY ROUTE CONTROLLER
s
-ZB

*/

interface props {
	children?: ReactNode
}

const QRLRouter: FC<props> = ({ children }) => {
	return (
		<Routes>
            <Route path="/qrl/home" element={<Home />} />
			<Route path="/qrl/documentation" element={<Documentation />} />
			<Route path="/qrl/training" element={<TrainingLibrary />} />
			<Route path="/qrl/media" element={<MediaLibrary />} />
			<Route path="/qrl/models" element={<Models />} />
			<Route path="/qrl/procedures" element={<Procedures />} />
			<Route path="/qrl/external-content" element={<ExternalContent />} />
		</Routes>
	);
};

export default QRLRouter;