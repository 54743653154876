import React, { FC } from 'react';
import styled from 'styled-components';

import { Drop } from '../../../../main/components';

import { P as Part } from '../../../../lib';
import { useGlobalContext } from '../../../../utils/';

/*

 DROP DOWN TABS FOR SORTING BY SPECIFIC PART ATTRIBUTES INSIDE OF QUICK PARTS
 

 ******* UNFINISHED ********

 - ZB

*/

interface props {
    className?: string,
    onClick: (element: Part) => void
}

const SearchComp: FC<props> = ({ className, onClick }) => {

    const [state] = useGlobalContext();

    const { activeLang } = state;

    const bomNames = ['WHLA1-ALGN', 'WHLA2-ALGN', 'WHLA3-ALGN', 'WHLA4-ALGN', 'WHLA5-ALGN'];
    const plantNames = ['Warren Truck Assembly Plant', 'Jefferson Assembly Plant', 'MACK Assembly Plant', 'Sterling Heights Assembly Plant', 'Windsor Assembly Plant'];

    return (
        <>
            <section className={className} >
                <div className="search-container">
                    <div className="drop-container">
                        <Drop names={bomNames} tag="BOM" placeholder="BOM" />
                        <Drop names={plantNames} tag="Plant" placeholder={activeLang.plant} />
                        {/* <Drop names={extensionNames} tag="Extension" placeholder="Extension" /> */}
                        {/* <button className="upload-btn" onClick={onClick}>Search<FaSearch size={20} /></button> */}
                    </div>
                </div>
            </section>
        </>
    );
};

const Search = styled(SearchComp)`

.search-container {
    width: 50em;
    height: 4em;

}

.drop-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 4.5em;
}

.upload-btn {
  text-transform: uppercase;
  outline: 0;
  background: #0069d9;
  border: 0;
  padding: 12px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
  background-color: #0069d9;
  border-radius: 10px;

  & svg {
    margin-left: 1em;
  }
}
.upload-btn:hover, .upload-btn:active, .upload-btn:focus {
  background: #6eb6e6;
}
`

export default Search;