import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { REMOVE_FILTER, SET_FILTER, useQPContext } from '../../../../utils';

import { P as part } from '../../../../lib';

interface props {
    className?: string,
    label: string,
    onClick: (element: part) => void,
    key: string
}

const CheckboxComp: FC<props> = ({ className, label, onClick, key }) => {

    const [state, dispatch] = useQPContext();
    const { activeFilters } = state;

    const [isChecked, setIsChecked] = useState(false);
    const [style, setStyle] = useState('inactive')

    const handleChange = () => {
        isChecked === false ? setStyle('inactive') : setStyle('active');
        isChecked === false ? setIsChecked(true) : setIsChecked(false);
        
    }

    useEffect(() => {
        if (isChecked && !activeFilters.includes(label))
        {
            dispatch({
                type: SET_FILTER,
                activeFilters: label
            })
            
        } else if (activeFilters.includes(label))
        {
            dispatch({
                type: REMOVE_FILTER,
                activeFilters: label
            })
        }
    },[isChecked, activeFilters, label, dispatch])

    return (
        <>
        <div className={className} >
            <div className="check" >
                <label className={`label ${style}`}>
                    <input 
                    type="checkbox"
                    value={label}
                    checked={isChecked}
                    onChange={handleChange}
                    />
                {label}
                </label>
            </div>
        </div>
        </>
    );
}

const Checkbox = styled(CheckboxComp)`

.check {
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
    
}

.check:hover {
    transform: scale(1.1);
}

.label {
    border: 2px black solid;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    border-radius: 10px;
}

.active {
    background-color: powderblue;
}

.inactive {
    background-color: white;
}

input {
    /* visibility: hidden; */
    margin-right: 10px;
    padding-right: 10px;
}

`

export default Checkbox;