import React from 'react';

import {
    GlobalProvider,
    QBProvider,
    QPProvider,
    QRLProvider
} from '../context';

const Provider = () => {
    return (
        <>
        <GlobalProvider />
        <QBProvider />
        <QPProvider />
        <QRLProvider />
        </>
    );
};

export default Provider;