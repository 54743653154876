import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { useQBContext, replaceWhereMatch } from '../../../utils';

/*

NODE COMPONENT FOR EDITOR MODULE

- ZB

*/

interface props {
    className?: string
    index: number
    node?: ReactNode
}

const NodeComp: FC<props> = ({ className, index, node }) => {
    const [state] = useQBContext();
    const { activeGrid, activeEditors } = state;
    const [hover, isHover] = useState('cell');

    const handleDragEnter = (e: any) => {
        isHover('cell select');
        let key = parseInt(e.target.id)
        replaceWhereMatch(activeEditors, activeGrid, index, key)
    }
    const handleDragExit = (e: any) => {
        isHover('cell');
    }


    return(
        <div className={className}>
            <div 
            className={`${hover}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragExit}
            id={index.toString()}
            >
                {node && node}
            </div>
        </div>
    )
};

const Node = styled(NodeComp)`
    
.cell {
    display: inline-block;
    /* border: 1px solid green; */
    min-width: 27em;
    min-height: 20em;
    width: 100%;
    height: 100%;
}

.select {
    border: 1px solid red;
    transform: scale(1.1);
    opacity: 60%;
    border-radius: 10px;
}

`

export default Node;