import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import GlobalStyles from './styles/GlobalStyles';
import Provider from './utils/providers';

// import * as serviceWorkerRegistration from './service-worker/serviceWorkerRegistration';
// const baseUrl: any = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker
			.register('/service-worker')
			.then((registration) => {
				console.log('Service worker registered successfully:', registration);
			})
			.catch((error) => {
				console.error('Error registering service worker:', error);
			});
	});
}

const MainApp: React.FC = () => {
	return (
		<>
			<Provider />
			<GlobalStyles />
			<Router />
		</>
	);
};

root.render(
	<BrowserRouter>
		<MainApp />
	</BrowserRouter>
);
