import { forwardRef, PropsWithChildren, Ref, FC, ReactNode, ReactElement } from 'react'
import styled from 'styled-components'
import { FaSpinner } from 'react-icons/fa';
import { HiSearchCircle } from 'react-icons/hi';

import { CommentElement, PostElement, User } from '../../lib/app';

/*

MORE FORWARD REF TOOLSETS, THIS FILE CONTAINERS THE SPINNER COMPONENT USED WHILE LOADING PAGES/CONTENT

- ZB

*/

interface props {
    className?: string
    children?: string | ReactNode | ReactElement
    visible?: string
    post?: PostElement
    postState?: PostElement
}

export const CommentList = forwardRef(
    (
        { className, children, visible, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        style={{
            display: `${visible}`,
            flexDirection: "column",
            backgroundColor: "antiquewhite",
            borderRadius: "10px",
            margin: "1em",
            maxHeight: "39em",
            minHeight: "fit-content",
            overflowY: "auto"
        }}
        >
        {children}
        </div>
    )
)


const Loader = forwardRef(
    (
        { className, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        />
    )
)

const SpinnerComp:FC <props> = ({ className }) => {
    return (
        <Loader className={className}>
            <div>
                <FaSpinner className="loading" size={50} />
            </div>
        </Loader>
    )
};

export const Spinner = styled(SpinnerComp)`
    
    @keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading {
  -webkit-animation: rotating 3s linear infinite;
  animation: rotating 3s linear infinite;
  color: white;
  opacity: 30%;
}

`

const SearchField = forwardRef(
    (
        { className, ...props } : PropsWithChildren<props>,
        ref: Ref<HTMLInputElement>
    ) => (
        <input
        {...props}
        ref={ref}
        className={className}
        placeholder='What are you looking for today?'
        >
        </input>
    )
);

const SearchBox = forwardRef(
    (
        { className, children, ...props } : PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        >
        <HiSearchCircle size={32} />
        <SearchField />
        </div>
    )
);

const SearchComp:FC <props> = ({ className, children }) => {
    return (
        <SearchBox className={className}>
                {children}
        </SearchBox>
    )
};

export const Search = styled(SearchComp)`

display: flex;
justify-content: center;
align-items: center;
    
input {
    border: none;
    background-color: antiquewhite;
    height: 2em;
}

svg {
    color: antiquewhite;
    align-items: center;
    margin-right: 5px;
}

`

interface CommentProps {
    className?: string,
    commentData: CommentElement,
    userData?: User
}

const CommentComp:FC <CommentProps> = ({ className, commentData, userData  }) => {
    return (
        <div className={className}>
            <div className="top">
                <h3>{userData && userData.username}</h3> 
                <p>{commentData.dateCreated.toLocaleString()}</p>
            </div>
            <p>{commentData.body}</p>
        </div>
    )
};

export const Comment = styled(CommentComp)`
    
padding: 10px;
background-color: #d6cab9;
margin: 10px;
border-radius: 10px;
max-width: 20em;

    & .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & p {
            font-style: italic;
            opacity: 80%;
        }

        & h3 {
            font-weight: 700;
        }

        & h3:hover {
            color: darkblue;
            cursor: pointer;
        }
    }


`
