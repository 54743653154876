import { FC, ReactNode } from 'react';
import Main from './Main';
import Body from './Body';
import ContentNav from './ContentNav';
import Header from './Header';
import Footer from '../Footer';

interface ShellProps {
	className?: string;
	children: ReactNode;
	nav?: string[];
}

const Shell: FC<ShellProps> = ({ className, children, nav }) => {
	return (
		<>
			<Main className={className}>
				<Header />
				{nav && (
					<ContentNav
					/>
				)}
				<Body>{children}</Body>
				<Footer />
			</Main>
		</>
	);
};

export { Body, Main, ContentNav, Shell };