import React, { FC } from 'react';
import styled from 'styled-components';
import { P as Part } from '../../../lib';

interface props {
    className?: string
    part: Part
}

const EditPartComp: FC<props> = ({ className, part }) => {
    return (
        <>
        <section className={className}>
            <section className="upload-container">
                <section className="form-container">
                    <form>
                        <h4>part number</h4>
                        <input placeholder={part.partNumber}></input>
                        <h4>extension</h4>
                        <input placeholder={part.extension}></input>
                        <h4>machine</h4>
                        <input placeholder={part.machine}></input>
                    </form>
                </section>
                    <section className="doc-container">
            
                    </section>
                </section>
                <button>
                    update part
                </button>
        </section>
        </>
    );
}

const EditPart = styled(EditPartComp)`

    overflow-y: auto;
    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
    border-radius: 10px;

.form-container {
    display: flex;
    flex-direction: column;
}

.doc-container {
    border: none;
    display: flex;
    flex-direction: column;
}

.doc-list {
    border: 1px solid red;
}

`

export default EditPart;