import { useReducer } from "react";
import { PopArr, deleteWhereIndex, deleteWhereMatch, findAndUpdate } from "../../helper/matchers";
import { 
    ADD_EDITOR,
    ADD_IMAGE,
    REMOVE_EDITOR,
    REMOVE_IMAGE,
    REMOVE_FROM_GRID,
    ADD_TO_GRID,
    UPDATE_EDITORS,
    UPDATE_GRID,
} from './actions';

import { State } from "./QBState";

export const reducer = (state: State, action: any) => {

    switch (action.type) {
        case ADD_EDITOR:
            return {
                ...state,
                activeEditors: [...state.activeEditors, action.activeEditors]
            }
        case ADD_IMAGE:
            return {
                ...state,
                activeImages: [...state.activeImages, action.activeImages]
            }
        case REMOVE_EDITOR:
            return {
                ...state,
                activeEditors: deleteWhereMatch(action.activeEditors, action.activeGrid, action.key)
            }
        case REMOVE_IMAGE:
            return {
                ...state,
                activeImages: PopArr(state.activeImages)
            }
        case REMOVE_FROM_GRID:
            return {
                ...state,
                activeGrid: deleteWhereIndex(action.arr, action.activeGrid, action.key)
            }
        case ADD_TO_GRID:
            return {
                ...state,
                activeGrid: [...state.activeGrid, action.activeGrid]
            }
        case UPDATE_EDITORS:
            return {
                ...state,
                activeEditors: findAndUpdate(action.activeEditors, action.element)
            }
        case UPDATE_GRID:
            return {
                ...state,
                activeGrid: action.activeGrid
            }         
        default:
            return state;

        
    }
    
};

export function useQBReducer(initialState: State) {
    return useReducer(reducer, initialState);
}