import { FC, ReactNode, useState } from "react"
import {
  Editor,
} from 'slate'
import BuilderButton from "./BuilderButton"

/*

BUTTON THAT HANDLES ADJUSTING STYLES TO TEXT INSIDE AN EDITOR
(ITALICS, BOLD, LIST ELEMENTS, ETC)

- ZB

*/

interface props {
    format: string,
    icon: ReactNode,
    editor: Editor
}

const MarkButton: FC<props> = ({ format, icon, editor }) => {
    const [active, setActive] = useState<boolean>(false);
    const isMarkActive = (editor: Editor, format: string) => {
        const marks: any = Editor.marks(editor)
        return marks ? marks[format] === true : false
      }
    const toggleMark = (editor: any, format: string) => {
        const isActive = isMarkActive(editor, format)
            if (isActive) {
                setActive(true);
                Editor.removeMark(editor, format)
                
            } else {
                setActive(false);
                Editor.addMark(editor, format, true)
        }
        console.log(Editor.marks(editor));
        return active;
    }
    return (
        <div
        onMouseDown={e => {
        e.preventDefault()
        toggleMark(editor, format)
        }}
        >
        <BuilderButton 
        active={isMarkActive(editor, format)} 
        icon={icon}
        />
        </div>
    );
}

export default MarkButton;