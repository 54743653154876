import React, { FC } from 'react';
import styled from 'styled-components';
import MaintenanceTable from '../components/Table';
import { FaEdit, FaFileArchive, FaFileDownload, FaFileExcel, FaFilePdf, FaFilePowerpoint, FaTrashAlt } from 'react-icons/fa';
import { MdVerifiedUser } from 'react-icons/md';
import Activity from '../components/Activity';
import Container from '../layouts/Container';

interface props {
    className?: string
}

type document = {
    name: string,
    file: file,
    type: type,
    lastUpdated: Date,
    link: string,
}

enum file {
    zip,
    pdf,
    excel,
    powerpoint
}

enum type {
    SMP = "SMP",
    EWO = "EWO",
    OPL = "OPL",
    Ledger = "Ledger",
    Manual = "Manual"
}

const DocumentationComp:FC <props> = ({ className }) => {
    let date = new Date();

    // #region ARRAY

    let documents: document[] = [
        {
            name: "EXAMPLE",
            type: type.EWO,
            file: file.pdf,
            lastUpdated: date,
            link: "",
        },
        {
            name: "EXAMPLE 1",
            type: type.Manual,
            file: file.pdf,
            lastUpdated: date,
            link: "",
        },
        {
            name: "EXAMPLE 2",
            type: type.Ledger,
            file: file.zip,
            lastUpdated: date,
            link: "",
        },
        {
            name: "EXAMPLE 3",
            type: type.SMP,
            file: file.pdf,
            lastUpdated: date,
            link: "",
        },
        {
            name: "EXAMPLE 4",
            type: type.OPL,
            file: file.excel,
            lastUpdated: date,
            link: "",
        },
        {
            name: "EXAMPLE 5",
            type: type.OPL,
            file: file.powerpoint,
            lastUpdated: date,
            link: "",
        },
        {
            name: "EXAMPLE 6",
            type: type.Manual,
            file: file.powerpoint,
            lastUpdated: date,
            link: "",
        },
    ]

    // #endregion

    return (
        <Container className={className}>
                <MaintenanceTable>
                        {documents.map((document, i) => {
                            return (
                                <tr key={i}>
                                    <td>{document.type}</td>
                                    <td>
                                        {document.file === file.pdf && (
                                            <FaFilePdf
                                            size={30}
                                            color={'darkred'}
                                            />
                                        )}
                                        {document.file === file.excel && (
                                            <FaFileExcel
                                            size={30}
                                            color={'darkgreen'}
                                            />
                                        )}
                                        {document.file === file.zip && (
                                            <FaFileArchive
                                            size={30}
                                            color={'darkorange'}
                                            />
                                        )}
                                        {document.file === file.powerpoint && (
                                            <FaFilePowerpoint
                                            size={30}
                                            color={'darkblue'}
                                            />
                                        )}
                                    </td>
                                    <td>{document.name}</td>
                                    <td>{document.lastUpdated.toDateString()}</td>
                                    <td className="control-col">
										<FaFileDownload size={30} />
										<FaEdit size={30} />
										<FaTrashAlt size={30} />
										<a href="/admin/document-approval">
											<MdVerifiedUser size={30} />
										</a>
									</td>
                                </tr>
                            )
                        })}
                </MaintenanceTable>
                <Activity>
                        
                </Activity>
        </Container>
    )
};

const Documentation = styled(DocumentationComp)`

    .parent {
        background: rgb(64,48,54);
        background: linear-gradient(175deg, rgba(64,48,54,1) 64%, rgba(158,0,93,1) 98%);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: top;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        
        justify-content: inherit;
        width: 100%;

        & .body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 75vh;
            width: 80%;
            margin-left: 30vh;
        }

        & .search {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 7vh;
            display: flex;
            align-content: center;
        }

        & .control-col {
            & svg {
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        & .logo {
            width: 3em;
            height: 3em;
            margin-left: 5px;
            transition: 
                transform 1s,
                background-color 1s,
                mix-blend-mode 1s;
        }

        & .logo:hover {
            transform: scale(1.01);
            color: aliceblue;
            mix-blend-mode: lighten;
        }
    }





`

export default Documentation;