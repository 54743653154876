import React, { createContext, useContext } from 'react';

import { useQRLReducer } from './reducers';

/*

QUICK RESPONSE LIBRARY CONTEXT INITIALIZER
VALUES THAT EXIST WITHIN THE CONTEXT OF QRLAPP.TSX SHOULD BE WRITTEN HERE

*/

export interface State {
    testVal: boolean,
}

const QRLContext = createContext<[State, React.Dispatch<any>]>([
    {
        testVal: false,
    },
    () => {}
]);

const { Provider } = QRLContext;

const QRLProvider = ({ value = [], ...props }) => {
    const [state, dispatch] = useQRLReducer({
        testVal: false,
    });
    return <Provider value={[state, dispatch]} {...props} />
};

const useQRLContext = () => {
    return useContext(QRLContext);
};

export { QRLProvider, useQRLContext };