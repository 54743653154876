import { FC, ReactNode } from "react"

import { Element as SlateElement } from 'slate'

/*

COMPONENT FOR CONTROLLING TEXT STYLING INSIDE EDITOR

- ZB

*/

interface props {
    attributes: SlateElement
    children: ReactNode,
    leaf: any
}

const Leaf: FC<props> = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
  
    if (leaf.code) {
      children = <code>{children}</code>
    }
  
    if (leaf.italic) {
      children = <em>{children}</em>
    }
  
    if (leaf.underline) {
      children = <u>{children}</u>
    }
  
    return <span {...attributes}>{children}</span>
  }

export default Leaf;