import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../main/components';

import { useQPContext, useGlobalContext, ACTIVE_WINDOW } from '../../../../utils';

import { FaWrench, FaFilePdf, FaGlasses, FaExclamationTriangle, FaArrowLeft, FaFileDownload, FaVideo, FaReadme } from 'react-icons/fa';
import DocsList from '../DocsList';
import DocPreview from '../DocPreview';

/*

LIST OF DOCUMENT BUTTONS

NEEDS TO BE UPDATED TO BE CONDITIONAL

- ZB

*/

interface props {
    className?: string,
}

const DocumentsComp: FC<props> = ({ className }) => {

    const [state, dispatch] = useQPContext();
    const [globalState] = useGlobalContext();
    const { activePart, activeWindow } = state;
    const { activeLang } = globalState;
    const [window, setWindow] = useState<number>();


    useEffect(() => {
        console.log('help measd oh god')
            dispatch({
                type: ACTIVE_WINDOW,
                activeWindow: window
            })
            console.log(activeWindow);
    }, [window, activeWindow, dispatch]);

    useEffect(() => {
        activeWindow !== 0 ? setWindow(w => activeWindow) : setWindow(w => window);
    },[activeWindow, window])

    const handleBackList = () => {
        console.log('clicked List back button');
        setWindow(0);
        console.log(window);
    }

    const handleBackPDF = () => {
        console.log('clicked PDF back button')
        setWindow(1);
    }


        return (
            <>
 
            {activeWindow === 0 || !activeWindow ? 
                <section className={className} >
                    <div className="parts-container">
                        <div className="guide">
                            <button className="upload-btn"  >
                                <FaArrowLeft size={20} />
                            </button>
                            <h3>
                            {activeLang.documentation}
                            </h3>
                            <button className="upload-btn">
                                <FaFileDownload size={20} />
                            </button>
                        </div>
                        <div className="guide">
                            <h3>{activeLang.selected}: {activePart.partNumber}</h3>
                        </div>
                        <div className="parts-holder" >
                            <Button 
                                // onClick={onClick}
                                id="opl"
                                className="parts-btn-opl"
                                docName="opl"
                                displayName="OPL"
                                icon={<FaWrench size={50} />} 
                            />
                            <Button 
                                // onClick={onClick} 
                                id="ewo" 
                                className="parts-btn-ewo" 
                                docName="ewo"
                                displayName="EWO" 
                                icon={<FaExclamationTriangle size={50} />} 
                            />
                            <Button 
                                // onClick={onClick} 
                                id="smp" 
                                className="parts-btn-smp" 
                                docName="smp"
                                displayName="VJP" 
                                icon={<FaFilePdf size={50} />}
                            />
                            <Button 
                                // onClick={onClick} 
                                id="oem" 
                                className="parts-btn-man" 
                                docName="oem"
                                displayName={`OEM ` + activeLang.manual}
                                icon={<FaGlasses size={50} />} 
                            />
                            <Button 
                                // onClick={onClick} 
                                id="video" 
                                className="parts-btn-vid" 
                                docName="video"
                                displayName={activeLang.videos}
                                icon={<FaVideo size={50} />} 
                            />
                            <Button 
                                // onClick={onClick} 
                                id="sop"
                                className="parts-btn-sop" 
                                docName="sop"
                                displayName="SOP"
                                icon={<FaReadme size={50} />} 
                            />
                        </div>
                    </div>
                </section>
                : null }
                {activeWindow === 1 ?
                <section className={className} >
                    <div className="parts-container">
    
                        <div className="guide">
                        <button className="upload-btn" onClick={handleBackList}>
                            <FaArrowLeft size={20} />
                        </button>
                            <h3>
                            {activeLang.documentation}
                            </h3>
                        <button className="upload-btn">
                            <FaFileDownload size={20} />
                        </button>
                        </div>

                        <DocsList />
    
                    </div>
                </section>
                : null }
                {activeWindow === 2 ?
                <section className={className} >
                    <div className="parts-container">

                        <div className="guide">
                            <button className="upload-btn" onClick={handleBackPDF}>
                                <FaArrowLeft size={20} />
                            </button>
                                <h3>
                                {activeLang.documentation}
                                </h3>
                            <button className="upload-btn">
                                <FaFileDownload size={20} />
                            </button>
                        </div>
                        <DocPreview selected={false} />
                    </div>
                </section>
                : null }

        </>
        );

};

const Documents = styled(DocumentsComp)`

.parts-container {
    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
    width: 45em;
    height: 48em;
    margin-top: 4em;
    margin-left: 1em;
    margin-bottom: 1.5em;
    border-radius: 20px;

    & h1 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
    }
}

.guide {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    padding-top: 1em;
    padding-bottom: 1em;
    & h3 {
        color: #FFFFFF;
        font-weight: 700;
        opacity: 70%;
    }
}

.parts-holder {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;
}

.parts-btn-opl {
    width: 20em;
    padding: .5em;

    & Button {
        background-color: forestgreen;
        
        & h3 {
            background-color: #fff;
            text-transform: uppercase;
        }

        & svg {
            color: #fff
        }
    }
}

.parts-btn-smp {
    width: 20em;
    padding: .5em;

    & Button {
        background-color: teal;
        
        & h3 {
            background-color: #fff;
            text-transform: uppercase;
        }

        & svg {
            color: #fff
        }
    }
}

.parts-btn-ewo {
    width: 20em;
    padding: .5em;

    & Button {
        background-color: #D0342C;

        & h3 {
            background-color: #fff;
            text-transform: uppercase;
        }

        & svg {
            color: #fff
        }
    }
}

.parts-btn-man {
    width: 20em;
    padding: .5em;

    & Button {
        background-color: orange;

        & h3 {
            background-color: #fff;
            text-transform: uppercase;
        }

        & svg {
            color: #fff
        }
    }
}

.parts-btn-vid {
    width: 20em;
    padding: .5em;

    & Button {
        background-color: powderblue;

        & h3 {
            background-color: #fff;
            text-transform: uppercase;
        }

        & svg {
            color: #fff
        }
    }
}

.parts-btn-sop {
    width: 20em;
    padding: .5em;

    & Button {
        background-color: blueviolet;

        & h3 {
            background-color: #fff;
            text-transform: uppercase;
        }

        & svg {
            color: #fff
        }
    }
}


.upload-btn {
  text-transform: uppercase;
  outline: 0;
  background: #0069d9;
  border: 0;
  padding-left: 2em;
  padding-right: 2em;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
  background-color: #0069d9;
  border-radius: 10px;
  margin-right: 5em;
  margin-left: 5em;
}
.upload-btn:hover, .upload-btn:active, .upload-btn:focus {
  background: #6eb6e6;
}
`

export default Documents;