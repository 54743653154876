import { useReducer } from "react";
import { State } from "./QRLState";

import {
    TEST_ACTION
} from './actions';

export const reducer = (state: State, action: any) => {
    switch (action.type)
    {
        case TEST_ACTION:
            return {
                ...state,
                activeLang: action.activeLang
            }
        default:
            return state;
    }
};


export function useQRLReducer(initialState: State) {
    return useReducer(reducer, initialState);
}