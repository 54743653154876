import React, { FC } from 'react'
import styled from 'styled-components';
import { Editor } from 'slate'

import { EditText } from '../EditText';

import { useQBContext, UPDATE_EDITORS } from '../../../utils';
import { FaParagraph } from 'react-icons/fa';

/*

BUTTON THAT HANDLES ADDING TEXT EDITORS TO THE MAIN BUILD FORM

- ZB

*/


interface props {
    className?: string
    editor: Editor
}

const AddTextButtonComp: FC<props> = ({ className, editor }) => {
    const [state, dispatch] = useQBContext();
    const { activeEditors, activeImages } = state;

    function randInt(max: number) {
        return Math.floor(Math.random() * max)
    }


    const onClick = (e: any) => {
        e.preventDefault();
        dispatch({
            type: UPDATE_EDITORS,
            activeEditors: activeEditors,
            element: <EditText key={randInt(1000000)} editor={editor} index={activeEditors.length + activeImages.length} />
        })
        console.log(activeEditors);
    }
    return (
        <div className={className}>
            <button 
            onClick={e => onClick(e)}
            >
                ++
            <FaParagraph />
            </button>
        </div>
    )
}

const AddTextButton = styled(AddTextButtonComp)`


    button {
        border: none;
        border-radius: 5px;
        box-shadow: 3px 3px 3px rgba(30,30,30,0.3);
        height: 3em;
        width: 6em;
        background-color: powderblue
    }

    button:hover {
        transform: scale(1.1);
    }

    svg {
        margin-left: 5px;
    }

`

export default AddTextButton;