import styled from 'styled-components';

const Main = styled.main`
	/* display: flex; */

	// mobile adjustments
	@media screen and (max-width: 480px) {
		width: 100%;
	}
`;

export default Main;