import { ReactElement, ReactNode, FC } from 'react';
import styled from 'styled-components';

interface props {
    className?: string,
    children?: string | ReactElement | ReactNode | JSX.Element
}

const TableComp: FC <props> = ({ className, children }) => {
	return (
		<table
			className={className}
		>
			<thead>
				<tr>
					<th id="type">document type</th>
					<th id="file">file type</th>
					<th id="name">document name</th>
					<th id="date">last update</th>
					<th id="controls">admin control</th>
				</tr>
			</thead>
			<tbody>{children}</tbody>
		</table>
	);
};

const MaintenanceTable = styled(TableComp)`
	text-align: center;
    border: none;
	background-color: antiquewhite;
    width: 60vw;
    border-radius: 10px;
	margin-top: 1em;
	margin-right: 1em;
	min-height: 60vh;
	max-height: 100vh;
	overflow-y: auto;

	& th {
		color: #121111;
		text-transform: uppercase;
	}

	& #type {
		width: 8vw;
	}
	& #file {
		width: 6vw;
	}
	& #date {
		width: 8vw;
	}
	& #controls {
		width: 15vw;
	}


	tbody tr:nth-child(odd) {
    background-color: #efdec9;
}

	tbody tr:hover {
    background-color: #a8a8d1;
    opacity: 80%;
    cursor: pointer;
    font-style: italic;
}
`;

export default MaintenanceTable;