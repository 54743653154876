import { Lang, Dict } from "./Dict"

export const KR: Dict = new Lang(
    "한국",
    "환영",
    "문서조사",
    "새 경고 (EWO)",
    "업로드",
    "공구",
    "수",
    "공구 번호",
    "공장",
    "수동",
    "기계",
    "확장",
    "동영상",
    "선택한",
    "도구 검색",
)