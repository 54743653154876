import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face
{
  font-family: embrima;
  src: url('fonts/ebrima.ttf');
} 

@font-face
{
  font-family: embrimabold;
  src: url('fonts/ebrima-bold.ttf');
}

@font-face
{
    font-family: 'Encode Sans Condensed';
    src: url('fonts/EncodeSansCondensed-Regular.ttf')
}

:root {
    --primary: #6eb6e6;
    --secondary: #0069d9;
    --black: #000;
    --white: #fff;
    --gray: #ccc;
    --orange: #ff6b25;
		--header-height: 7vh;
		--header-background: var(--primary-blue);

    --qrl-purple: #713458;
    --qbk-blue: #666396;

		--footer-height: 8vh;
		--footer-background: none;

    --navbar-width: 13vw;
    --navbar-height-mobile: 8vh;
    --navbar-width-mobile: 18vw;
		
		--tooltip-text-color: white;
		--tooltip-background-color: black;
		--tooltip-margin: 30px;
		--tooltip-arrow-size: 6px;
}

*,::after::before {
  box-sizing: border-box
}

body {
    /* font-family: 'Encode Sans Condensed', 'ebrima', arial, helvetica, sans-serif; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing:.01em;
    margin: 0;
    padding: 0;
    height: 100%;
    color:  #333;
    background-color: rgba(0,0,0,.09);
}

`

export default GlobalStyles;