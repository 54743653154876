// GLOBAL IMPORTS
import React, { FC, useState, useEffect } from 'react';
import { useQPContext } from '../../utils/context/QP/QPState';
import { UPDATE_PARTS, FILTER_PARTS } from '../../utils/context/QP/actions';
import styled from 'styled-components';

// INTERNAL IMPORTS
import { 
    Documents,
    PartsList,
    Hotbar 
} from '../components';

import fetchNest from '../../utils/helper/limiter';
import { filterArr, filterEmpties } from '../../utils/helper/matchers';
import { P as Part } from '../../lib';
import { Shell } from '../../main/components';

interface props {
    className?: string
}

/* 

MAIN DASHBOARD PAGE CONTAINING THE PARTSLIST AND INTERACTIVE DOCUMENT COMPONENTS
RESPONSIBLE FOR MANAGING THE FRONT END PART FILTER, SORTING AND ARRAYS

*/

const DashboardComp: FC<props> = ({ className }) => {

    const [state, dispatch] = useQPContext();

    const { filteredParts, activeParts, activeFilters} = state;

    const [filteredList, setFilteredList] = useState<Part[]>([]);
    const [baseList, setBaseList] = useState<Part[]>([]);
    const [load, setLoad] = useState(true);
    const callLimiter = 1000;


    useEffect(() => {
        const newParts = async () => {
            console.log("called");
            const list: Part[] = await fetchNest(callLimiter);
            setFilteredList(list)
            setBaseList(list)
            setLoad(false);
        }
        setLoad(true);
        newParts();

        dispatch({
            type: UPDATE_PARTS,
            activeParts: baseList
        })
    },[dispatch, activeParts, baseList])

    useEffect(() => {
        dispatch({
            type: UPDATE_PARTS,
            activeParts: filteredList
        })
        console.log(baseList);
    },[baseList, activeParts, filteredList, dispatch])

    useEffect(() => {
        if (baseList)
        {
            dispatch({
                type: UPDATE_PARTS,
                activeParts: baseList
            })
        }
    },[dispatch, baseList, load]);

    useEffect(() => {
        if (filteredList)
        {
            dispatch({
                type: FILTER_PARTS,
                filteredParts: filteredList
            })
        }
    },[dispatch, filteredList, load])

    const filterPartsArray = () => {
        setFilteredList(filterArr(baseList, activeFilters));
    }

    useEffect(() => {
        if (activeFilters.length === 0)
        {
            setFilteredList(baseList);
        } else {
            setFilteredList(filterEmpties(baseList, activeFilters));
        }
    },[activeFilters, baseList])
        return (
            <>
            <Shell className={className}>
                <section className="parent">
                    <Hotbar />
                    
                    <PartsList onClick={filterPartsArray} parts={filteredParts}/>
                    <Documents />
                </section>
            </Shell>
            </>
        );
};

const Dashboard = styled(DashboardComp)`

    .parent {
        background: rgb(64,48,54);
        background: linear-gradient(175deg, rgba(64,48,54,1) 64%, rgba(158,0,93,1) 98%);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: top;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        align-items: center;
        justify-content: inherit;
        width: 100%;
    }

`

export default Dashboard;