//packages
import styled from 'styled-components';
import { FC } from 'react';
const poweredBy = require('./images/powered-by.png');

/*

	ITS FOOTER CODE WRITTEN BY TT
	USED APP WIDE

	-ZB
	
*/

interface props {
	className?: string
}

// footer content and logic
const FooterComp: FC<props> = ({ className }) => {
	const link = 'https://www.interactivetrainingsystems.com';
	return (
		<footer className={className}>
			<div className="footer-left">
				<a href={link}>
					<img className="footer-logo" src={poweredBy} alt="logo" />
				</a>
			</div>
			<div className="footer-right">
			</div>
		</footer>
	);
};

// footer styling
const Footer = styled(FooterComp)`
	z-index: 100; // layer footer above anything else
	position: fixed; // stick in place
	bottom: 0; // stick to bottom of page
	width: 100%; // width of entire page
	/* border-top: solid #fff; */
	/* background-image: linear-gradient(to bottom right, rgba(0,0,0,0.8), rgba(0,0,0,0.8));
	background-repeat: no-repeat;
	background-size: cover; */
	/* backdrop-filter: blur(1em); */
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
	opacity: 40%;

	// left section
	.footer-left {
		margin: auto 2rem;
		float: right;

		// powered by text
		.powered-by {
			margin: auto 0;
			padding: 15px;
			font-weight: bold;

		}

		// lower-left logo
		.footer-logo {
			height: 100%;
			background-color: #111111;
			padding: 10px;
			border-radius: 10px;
			margin-bottom: 10px;
		}

		& a {
			height: 85%;
		}

		& img {
			padding: .2em;
		}
	}

	// right section
	.footer-right {
		display: flex;
		margin: auto 2rem;
		/* color: #000; */

		& h1 {
			font-weight: 700;
		}

		// footer right buttons
		& button {
			margin: auto 0.4vw;
			height: 90%;
			width: 3.5vw;

			// footer right buttons hover
			&:hover {
				transform: scale(1.05);
				filter: opacity(0.8);
			}
		}
	}

	&:hover {
		opacity: 100%;
	}

	@media screen and (max-width: 480px) {
		background: var(--primary);
		position: relative;

		.footer-left {
			padding: 10px;
			margin: 0;
		}

		.footer-right {
			& h1 {
				font-size: 16px;
				margin: auto;
			}
		}
	}
`;

export default Footer;

// <Footer />
