import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Search } from '../../Navigation';
import { PartButton, Checkbox } from '../../../../main/components';
import Table from '../Table';

import { FaToolbox, FaSadTear, FaEdit, FaPuzzlePiece, FaAdjust, FaTable, FaRegHandPointer, FaFileExcel } from 'react-icons/fa';
import { useQPContext, FILTER_PARTS } from '../../../../utils';
import { P as Part } from '../../../../lib';

/*

COMPONENT CONTAINER FOR PART ICON / TABLE VIEWERS

*/

const docTypes = [
    'opl',
    'ewo',
    'smp',
    'oem',
    'video',
    'sop'
]

interface props {
    className?: string,
    onClick: (element: Part) => void,
    parts: Part[]
}

const PartsListComp: FC<props> = ({ className, onClick, parts }) => {

    const [state, dispatch] = useQPContext();

    const { filteredParts } = state;

    const [filter, setFilter] = useState<Part[]>([]);
    const [listView, setListView] = useState(false);
    const [emptyFilter, setEmptyFilter] = useState(false)

    useEffect(() => {
        setFilter(parts)
        console.log(parts);
    },[parts])

    useEffect(() => {
        dispatch({
            type: FILTER_PARTS,
            filteredParts: parts
        })
    },[filter, dispatch, parts])

    const hasEmptyArrs = (p: Part) => {

        if (!p.ewo) { return; }
        if (!p.smp) { return; }
        if (!p.opl) { return; }
        if (!p.oem) { return; }
        if (!p.sop) { return; }
        if (!p.video) { return; }

        if (p.smp.length === 0 && 
            p.ewo.length === 0 && 
            p.opl.length === 0 && 
            p.sop.length === 0 &&
            p.video.length === 0)
        {
            return p;
        }
    }

    const handleFilter = () => {
        !emptyFilter ? setEmptyFilter(true) : setEmptyFilter(false);
    }

    const handleView = () => {
        !listView ? setListView(true) : setListView(false);
    }

    if (listView)
    {

        // TOTAL MESS WITH TWO RETURNS -- WILL FIX SOON.
    return (
        <>
            <section className={className} >
                <Search onClick={onClick} />
                <div className="tabs">
                    <button className={!emptyFilter ? "tab-btn" : "tab-btn selected"} onClick={handleFilter}>{!emptyFilter ? <FaPuzzlePiece size={30} /> : <FaAdjust size={30} />}</button>
                    <button className="tab-btn edit"><FaEdit size={30} /></button>
                    <button className="tab-btn view" onClick={handleView}><FaTable size={30} /></button>
                    <button className="tab-btn excel"><FaFileExcel size={30} /></button>
                </div>
                        <section className="boxes"> 
                            {docTypes.map(d => {
                                return <Checkbox label={d} key={d} onClick={onClick} />
                            })}
                        </section>

                <div className="doc-container">

                    <div className="list">
                        {!emptyFilter && filteredParts && filteredParts.map(p => {
                            return <PartButton className="parts-btn" key={p.id} displayName={`${p.partNumber}`} icon={<FaToolbox size={50} />} element={p}/>
                        })}
                        {emptyFilter && filteredParts && filteredParts.filter((p) => hasEmptyArrs(p)).map((p) => {
                            return <PartButton className="empty-parts-btn" key={p.id} displayName={`${p.partNumber}`} icon={<FaSadTear size={50} color="white" />} element={p}/>
                        })}
                    </div>
                    
                </div>
            </section>
        </>
    );
    } else {
    return (
        <>
            <section className={className} >
                <Search onClick={onClick} />
                <div className="tabs">
                    <button className={!emptyFilter ? "tab-btn" : "tab-btn selected"} onClick={handleFilter}>{!emptyFilter ? <FaPuzzlePiece size={30} /> : <FaAdjust size={30} />}</button>
                    <button className="tab-btn edit"><FaEdit size={30} /></button>
                    <button className="tab-btn view" onClick={handleView}><FaRegHandPointer size={30} /></button>
                    <button className="tab-btn excel"><FaFileExcel size={30} /></button>
                </div>

                <section className="boxes"> 
                    {docTypes.map(d => {
                        return <Checkbox label={d} key={d} onClick={onClick} />
                    })}
                </section>

                <div className="doc-container">
                {!emptyFilter && filteredParts ? <Table arr={filteredParts} icon={<FaToolbox size={20}/>}/>
                : null
                }

                {emptyFilter && filteredParts ? 
                <>
                <Table arr={filteredParts.filter((p) => hasEmptyArrs(p))} icon={<FaToolbox size={20}/>}/>
                </>
                : null
                }
                </div>
                
            </section>
        </>
    )
    }
};

const PartsList = styled(PartsListComp)`

.doc-container {
    overflow-y: auto;
    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
    border-radius: 20px;
    min-height: 30em;
    max-height: 40em;
    width: 56em;
    max-width: 56em;
}

.empty-part-container {
    margin-top: 1em;
    overflow-y: auto;
    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
    border-radius: 20px;
    min-height: 30em;
    max-height: 40em;
    width: 56em;
    max-width: 56em;
}

.boxes {
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    margin-bottom: .5em;
    border-radius: 10px;
}

.check {
    margin-left: 1em;
    margin-right: 1em;
}

.guide {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    
    & h3 {
        color: #FFFFFF;
        font-weight: 700;
        opacity: 90%;
        text-align: center;
    }
    & .break {
        font-style: italic;
        opacity: 70%;
    }
}

.empty-parts-btn {
    width: 10em;
    padding: .5em;

    & Button {
        background-color: red;
    }

    & h3 {
        color: #000;
        background-color: white;
    }
}

.list {
    width: 100%;
    height: 100%;
    padding: 1em;
    padding-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
}

.parts-btn {
    width: 15em;
    padding: .5em;
}


.upload-btn {
  text-transform: uppercase;
  outline: 0;
  width: 35%;
  background: #0069d9;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
  margin-bottom: 2em;
  margin-left: 4em;
  margin-right: 2em;
  background-color: #F47174;
  border-radius: 10px;
}
.upload-btn:hover, .upload-btn:active, .upload-btn:focus {
  background: #6eb6e6;
}

.tabs {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 3.4em;
    top: 35%;

    & svg {
        margin-right: 30em;
        margin-left: -.6em;
    }
}

.tab-btn {
  text-transform: uppercase;
  outline: 0;
  background: #0069d9;
  border: 0;
  padding-left: 2em;
  padding-right: 2em;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
  background-color: red;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  min-height: 5em;
  z-index: 0;
  width: 3em;
  margin-top: 3px;
  text-align: center;
}
.tab-btn:hover, .tab-btn:active, .tab-btn:focus {
  background: #6eb6e6;
  transform: scaleX(1.3);

  & svg {
    transform: scaleX(.75)
  }
}

.view {
    background: teal;
}

.edit {
    background: orange;
}

.excel {
    background: darkgreen;
}

.selected {
    background: yellow;
}



svg {
    margin-left: 2em;
}
`

export default PartsList;