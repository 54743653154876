export abstract class Dict {
    abstract language: string;
    abstract welcome: string;
    abstract documentation: string;
    abstract newAlerts: string;
    abstract upload: string;
    abstract part: string;
    abstract number: string;
    abstract partNumber: string;
    abstract plant: string;
    abstract manual: string;
    abstract machine: string;
    abstract extension: string;
    abstract videos: string;
    abstract selected: string;
    abstract search: string;

}

export class Lang extends Dict {
    language: string;
     welcome: string;
     documentation: string;
     newAlerts: string;
     upload: string;
     part: string;
     number: string;
     partNumber: string;
     plant: string;
     manual: string;
     machine: string;
     extension: string;
     videos: string;
     selected: string;
     search: string;

    constructor(
        language: string,
        welcome: string,
        documentation: string,
        newAlerts: string,
        upload: string,
        part: string,
        number: string,
        partNumber: string,
        plant: string,
        manual: string,
        machine: string,
        extension: string,
        videos: string,
        selected: string,
        search: string,
    )
    {
        super();
        this.language = language;
        this.welcome = welcome;
        this.documentation = documentation;
        this.newAlerts = newAlerts;
        this.upload = upload;
        this.part = part;
        this.number = number;
        this.partNumber = partNumber;
        this.plant = plant;
        this.manual = manual;
        this.machine = machine;
        this.extension = extension;
        this.videos = videos;
        this.selected = selected;
        this.search = search;

    }
}

