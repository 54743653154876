import React, { 
    FC, 
    ReactNode, 
    ReactElement, 
    forwardRef,
    PropsWithChildren,
    Ref,
    useState,
    useEffect,
} from 'react';
import styled from 'styled-components';
import { EPost, PostElement, CommentElement } from '../../lib/app';
import { FaBookmark, FaComment, FaExclamation, FaHeart, FaImage, FaVideo } from 'react-icons/fa';
import { HiDocument } from 'react-icons/hi';
import { AiFillNotification } from 'react-icons/ai';
import { Spinner } from '../../resources';
import { Comment, CommentList } from '../../resources/tools/Tools';
import Annotations from '../../resources/models/Annotations';
import { robot } from '../../resources'

// const ModelViewer = require('@google/model-viewer')

/*

THIS FILE HOLDS THE FEED AND POST COMPONENTS
THE POST USES CUSTOM POST TYPES AND A POST-TYPE ENUM (EPOST)

notes NOT YET IMPLEMENTED, WILL MOST LIKELY BE A SHARED / MODULAR COMPONENT

- ZB

*/

interface PostProps {
    className?: string
    children: string | ReactNode | ReactElement
}

interface FeedProps {
    className?: string
}

// forward ref for post element

const Post = forwardRef(
    (
        { className, children, ...props }: PropsWithChildren<PostProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        >
        {children}
        </div>
    )
)


const FeedComp:FC <FeedProps> = ({ className }) => {

    const [commentWindowActive, setCommentWindowActive] = useState<boolean>(false);
    const [posts, setPosts] = useState<PostElement[]>([]);

    const [visible, setVisible] = useState('');
    const [post, setPost] = useState<PostElement>();


    useEffect(() => {
        fetchPosts().then((list) => {
            setPosts(list);
        })
        
    },[])
    // current returning promise, need to change this.
    const fetchPosts = async () => {
        try {
            const res = await fetch('https://qsapi.interactivetrainingsystems.com:3000/blob');
            const data = await res.json();
            console.log(data);
            
            const createdList: PostElement[] = data.map((el: PostElement) => {
                el = {
                    ...el,
                    postType: EPost.Document
                }
                return el;
            })
            console.log(createdList);
            return createdList;
        } catch (err) {
            console.error(err);
            return [];
        }
    }


    const handleVisible = (p: PostElement) => {
       p === post  ? setVisible('flex') : setVisible('none');
       setPost(p);
    }

    // switch case for returning correct icon on post card regarding post type
    const renderIcon = (type: EPost): JSX.Element => {
        switch (type)
        {
            case EPost.Document:
                return <HiDocument size={30} />
            case EPost.Image:
                return <FaImage size={30} />
            case EPost.Update:
                return <AiFillNotification size={30} />
            case EPost.Video:
                return <FaVideo size={30} />
            default:
                return <FaExclamation size={30} />
        }
    };

    const renderPostContent = (post: PostElement) => {
        switch (post.postType)
        {
            case EPost.Document:
                return (
                    <div id="new">
                        <iframe title={post.file} src={post.file} />
                    </div>
                );
            case EPost.Image:
                return (
                    <img alt="" id="image" src={post.src} />
                );
            case EPost.Update:
                return (
                    <p id="text">
                    {post.bodyText && post.bodyText}
                    </p>
                )
            case EPost.Video:
                return (
                    <video id="video" src={post.src} />
                )
            case EPost.Model:
                return (
                    // <Scene />
                    <model-viewer src={robot} shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>
                )
            case EPost.Annotations:
                return (
                    // <Scene />
                    <Annotations />
                )
            default:
                return <Spinner />
        }
    }

    const handleCommentClick = () => {
        setCommentWindowActive(!commentWindowActive);
        console.log(commentWindowActive);
    }

    const displayCommentWindow = (p: PostElement) => {
        console.log(p.notes);
        return (
            <CommentList visible={visible} postState={post} post={p}>
                {p.notes.filter((el: CommentElement) => el !== undefined ).map(el => {
                    
                    return (
                        
                        <Comment key={el.id} commentData={el && el} userData={el.user && el.user}  />
                    ) 
                })}
            </CommentList>
        )
    }
    
    return (
        <section className={className}>
            {posts !== undefined ? posts.map((p, i) => {
                return (

                        <Post key={i}>
                        <div className="card">
                            {/* POST CONTENT */}

                            <div className="top-bar">
                                <h2>{p.blobName}</h2>
                            </div>
                            
                            <div className="item">
                                {renderPostContent(p)}
                            </div>

                            {/* INFO BAR LAYOUT */}
                            <div className="info-bar">
                                <ul>
                                    {/* DISPLAY COMMENT AMOUNT */}
                                    {p.notes ? 
                                    <li id="notes" onClick={handleCommentClick}  >
                                        {p.notes && p.notes.length} <span> <FaComment size={20} /> </span>
                                    </li>
                                    :
                                    // ADD LOGIC FOR LEAVING A COMMENT
                                    <li id="notes">
                                    <FaComment size={20} />
                                    </li>
                                    }   
                                    <li id="date">
                                        {p.createdAt}
                                    </li>
                                    <li id="like">
                                        <FaHeart className="top-icon" size={30} />
                                    </li>
                                    <li id="save">
                                        <FaBookmark className="top-icon" size={30} />
                                    </li>
                                    <li id="type">
                                        {renderIcon(p.postType!)}
                                    </li>
                                </ul>
                            </div>
                        </div>
                                {/* COMMENT FEED DISPLAYED NEXT TO POST */}
                                <div onClick={() => handleVisible(p)}>
                                    {commentWindowActive && displayCommentWindow(p)}
                                </div>
                </Post>
                );
            })
            : null
        }
        </section>
    )
};

const Feed = styled(FeedComp)`

.card {
    max-width: 50em;
    max-height: 50em;
    background-color: antiquewhite;
    padding: .5em;
    margin-top: 1em;
    margin-bottom: .5em;

    & .info-bar {
        background-color: #e1d5d5;
        padding-top: .5em;
        border-radius: 10px;
    }

    & .top-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;

        & h2 {
            max-width: 85%;
            background-color: #e1d5d5;
            border-radius: 10px;
            color: #111111;
            padding: 4px;
            font-weight: 700;
        }
    }

    & ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1em;

        & li {
            margin: 0 .5em;
            align-content: center;
            cursor: pointer;
        }

        & #type {
            color: #111111;
        }

        & #like {
            color: #111111;
        }

        & #save {
            color: #111111;
        }

        & #type:hover {
            color: darkblue;
        }

        & #date {
            font-style: italic;
            opacity: 80%;
        }

        & #notes {
            font-weight: 550;
            background-color: #111111;
            padding: 5px;
            border-radius: 10px;
            color: antiquewhite;
        }

        & #notes:hover {
                background-color: darkblue;
        }
    }

    & .item {
        display: flex;
        justify-content: center;

        & #video {
            max-width: 50em;
            margin: 0 auto;
            padding: 10px;
            position: relative;
        }

        & #image {
            margin: auto;
            height: auto;
            max-height: 30em;
            width: auto;
            max-width: 100%;
            padding-bottom: 10px;
            border-radius: 10px;
        }


    }

    #comment-feed {
        max-width: 10em;
        margin-right: 10px;
        border: 1px solid white;
    }

    model-viewer {
        min-height: 30em;
    }


    @media screen and (max-width: 480px) {

        & .top-bar {
            display: flex;
            margin: 0 auto;
            max-width: 100%;

            h2 {
                max-width: 100%;
                margin: 0 auto;
            }
        }

        & ul {
            margin-top: 2px;

            & li {

            }

            & #type {

            }
        }
    }
}

`

export default Feed;