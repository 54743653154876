import React, { FC, useState } from 'react';
import { Drop } from '../../../main/components';
import styled from 'styled-components';
import { Spinner } from '../../../resources';

interface props {
  className?: string
}


// error laden drag and drop functionality for uploading csv files.
const FormComp: FC<props> = ({ className }) => {

        const deptNames = ['BIW', 'BA', 'PNT'];
        const plantNames = ['Warren Truck Assembly', 'Jefferson Assembly', 'Mack Assembly']
        const [dragActive, setDragActive] = React.useState(false);

        const [activeFiles, setFiles] = useState<File[]>([]);
        const [progress, setProgress] = useState<boolean>(false);
        const [complete, setComplete] = useState<boolean>(false);
        
        const handleDrag = function(e: any) {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
              
                setDragActive(true);
            } else if (e.type === "dragleave" && dragActive) {
                setDragActive(false);
                
            }
        };
        // drag and drop is adding file to filelist but not triggering the handleDrop function.
        const handleDrop = function(e: any) {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {

              handleFiles(e.dataTransfer.files);
            }
          };
          // handleChange is not being triggered under any circumstances
          const handleChange = function(e: any) {
            console.log(activeFiles)
            e.preventDefault();
            if (e.target.files) {

              handleFiles(e.target.files);
              
            }
            
          };
            // throws error useref is wrong
          // const onButtonClick = () => {
          //   inputRef.current.click();
          // }

          const handleFiles = (files: any) => {
            setFiles(arr => {
            if (activeFiles.length === 0)
            {
              return [
                files[0]
              ]
            } else {
              return [
                ...arr,
                files[0]
              ]
            }
          })
          
          }

          const uploadFiles = async (e: any, files: File[]) => {
          e.preventDefault();
          e.stopPropagation();
          // const formData = new FormData();
          // files.forEach(f => {
          //   formData.append('file', f)
          // })
          // formData.append('plant', activePlant);
          // formData.append('department', activeBom);

          // console.log(files[0])
          // console.log(formData)
          setProgress(true);
          const stopLoad = () => {
            setProgress(false);
            setComplete(true);
          }
          setTimeout(stopLoad, 5000);

          // try {
          //     const res = await fetch('api/BOM',
          //     {
          //       method: 'POST', 
          //       body: formData,
          //     },
          //     );
          //     if (!res) {
          //       console.log('still uploading')
          //     }
          //     else if (res.ok) {
          //       console.log('success')
          //       setComplete(true);
          //     } else {
          //       console.log('failed')
          //     }
          //   } catch (err) {
          //     console.log(err)
          //   }
          // }
        }




    return (
        <>
            <section className={className} >
                <div className="form-container">
                <div className="btn-container">
                <Drop className="form-btn" tag="DEPARTMENT" names={deptNames} />
                <Drop className="form-btn" tag="PLANT" names={plantNames} />
                </div>
                    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload"
                        onDragEnter={handleDrag} 
                        onDragLeave={handleDrag} 
                        onDragOver={handleDrag} 
                        onDrop={handleDrop}> 
                            {
                            !complete && !progress && activeFiles.length === 0 ? 
                            <div>
                            <p>Drag and drop your file here</p>
                            {/* <button className="upload-button">Upload a file</button> */}
                            </div>
                            : <div></div>
                            }
                            <div className="file-list">
                            {!complete && !progress && activeFiles && activeFiles.map((f,i) => {
                              return <button className="file" key={i}>{f.name}</button>
                            })}
                            {
                            !complete && !progress && activeFiles.length > 0 ? 
                            <button className="file upload" onClick={(e) => uploadFiles(e, activeFiles)}>UPLOAD</button> 
                            : <></>
                            }
                            {
                            !complete && progress && 
                            <Spinner />
                            }
                            {
                              complete &&
                              <div>
                                <p>Your BOM has been uploaded! Click anywhere to close this window.</p>
                              </div>
                            }
                            </div>
                        </label>
                    </form>

                </div>
            </section>
        </>
    );
};

const Form = styled(FormComp)`

.form-container {
    width: 100%;
    height: 30em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.form-btn {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  width: 10em;
}

.file-list {
  display: flex;
  flex-direction: column;
}

.file {
  border: none;
  border-radius: 10px;
  background-color: powderblue;
  margin-top: .5em;
  margin-bottom: .5em;
  padding: .5em;
  width: 25em;
  font-weight: 600;
}

.upload {
  background-color: lavender;
}

#form-file-upload {
  height: 30vh;
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 8rem;
  margin-top: 2rem;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: solid;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}
`

export default Form;