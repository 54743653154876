import { FC } from 'react';
import styled from 'styled-components';
import { teamList } from '../../lib/BookLists';
import { Spinner } from '../../resources';
import Container from '../layouts/Container';

const TeamsComp:FC <IBaseGlobalProps> = ({ className }) => {
    return (
                <>
                <section className={className}>
                    <Container>
                        <section className="feed-container">
                            <div className={className}>
                                {teamList.map((el, i) => {
                                    return (
                                    <div key={i}>
                                        {el.name}
                                    </div>
                                )
                                })}
                            </div>
                            <div className="spinner">
                                <Spinner />
                            </div>
                        </section>
    
                    </Container>
                </section>
            </>
    )
};

const Teams = styled(TeamsComp)`
    
`

export default Teams;