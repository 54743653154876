import React, { createContext, useContext } from 'react';

import { useAppReducer } from './reducers';

import { Lang, EN } from '../../../lib/translations';

/*

MAIN CONTEXT INITIALIZER
VALUES THAT EXIST WITHIN THE CONTEXT OF MAINAPP.TSX SHOULD BE WRITTEN HERE

VALUES SUCH AS LOG IN / USER INFO / LANGUAGE SELECTION

*/

export interface State {
    activeLang: Lang,
    defaultLang: Lang
}

const GlobalContext = createContext<[State, React.Dispatch<any>]>([
    {
        activeLang: EN,
        defaultLang: EN,
    },
    () => {}
]);

const { Provider } = GlobalContext;

const GlobalProvider = ({ value = [], ...props }) => {
    const [state, dispatch] = useAppReducer({
        activeLang: EN,
        defaultLang: EN,
    });
    return <Provider value={[state, dispatch]} {...props} />
};

const useGlobalContext = () => {
    return useContext(GlobalContext);
};

export { GlobalProvider, useGlobalContext };