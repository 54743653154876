import { useReducer } from "react";
import { filterState } from "../../helper/matchers";
import { 
    SET_FILTER,
    SET_MACHINE,
    SET_PART,
    SET_PLANT,
    UPDATE_PARTS,
    ACTIVE_WINDOW,
    ACTIVE_DOCS,
    SET_DEPARTMENT,
    SET_EXTENSION,
    FILTER_PARTS,
    SET_BOM,
    REMOVE_FILTER,
    SET_FILE,
} from './actions';

import { State } from "./QPState";

export const reducer = (state: State, action: any) => {

    switch (action.type) {
        
        case SET_PART:
            return {
                ...state,
                activePart: action.activePart
            };
        case UPDATE_PARTS:
            return {
                ...state,
                activeParts: action.activeParts
            }
        case ACTIVE_WINDOW:
            return {
                ...state,
                activeWindow: action.activeWindow
            }
        case ACTIVE_DOCS:
            return {
                ...state,
                activeDocs: action.activeDocs
            }
        case SET_DEPARTMENT:
            return {
                ...state,
                activeDepartment: action.activeDepartment
            }
        case SET_EXTENSION:
            return {
                ...state,
                activeExtension: action.activeExtension
            }
        case SET_MACHINE:
            return {
                ...state,
                activeMachine: action.activeMachine
            }
        case FILTER_PARTS:
            return {
                ...state,
                filteredParts: action.filteredParts
            }
        case SET_BOM:
            return {
                ...state,
                activeBom: action.activeBom
            }
        case SET_PLANT:
            return {
                ...state,
                activePlant: action.activePlant
            }
        case SET_FILTER:
            return {
                ...state,
                activeFilters: [...state.activeFilters, action.activeFilters]
            }
        case REMOVE_FILTER:
            return {
                ...state,
                activeFilters: filterState(state.activeFilters, action.activeFilters)
            }
        case SET_FILE:
            return {
                ...state,
                activeFile: action.activeFile
            }       
        default:
            return state;

        
    }
    
};

export function useQPReducer(initialState: State) {
    return useReducer(reducer, initialState);
}