import { FC } from "react";
import styled from "styled-components";
import { EXVIDEO, TRAINING } from "../../assets";

interface props {
    className?: string,
    video: string
}

const VideoComp:FC <props> = ({ className, video }) => {

    // async function loadVideo(video: string) {
    //     await import(`${video}`)
    //     .then(
    //         (res) => {
    //             console.log(res.default);
    //             return res.default;
    //         },
    //     )
    // };

    return (
        <section className={className} style={{ backgroundImage: `url(${TRAINING})` }}>
            <section className="__video-display">
                <h2 className="video-title"> {video ? video : 'Video Display' } </h2>
                    <div >

                    <video
                    className="video-playback"
                    key={video}
                    autoPlay
                    controls
                    >

                    <source src={EXVIDEO} type="video/mp4" />
                    </video>
                    </div>
            </section>
        </section>

    )
}

const VideoDisplay = styled(VideoComp)`

        width: 70rem;
        min-height: 40rem;
        max-width: 100%;
        min-width: 30%;
        border-radius: 1rem;
        box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);

    .__display-content {

    }

    .video-preview {
        border-radius: 1rem;
        border-top-left-radius: 0;
        width: 95%;
        height: 39.5rem;
        min-height: 40rem;
        max-width: 100%;
        min-width: 30%;
        padding-bottom: 1rem;
        object-fit: cover;
    }

    .video-title {
        color: #fff;
        font-weight: 800;
        text-align: center;
        background-color: #231f20;
        padding: 5px;
        border-top-right-radius: .8rem;
        border-top-left-radius: .8rem;
    }

    .video-overlay {
        background: transparent;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
    }

    @keyframes pulse {
        0% { transform: scale(1); color: var(--primary)}
        50% { transform: scale(1.1); color: var(--secondary)}
        100% { transform: scale(1); color: var(--primary)}
    }

    svg {
        z-index: 10;
        justify-content: center;
        animation-name: pulse;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        align-items: center;
        align-self: center;
        align-content: center;
        margin: 0 auto;

        &:hover {
            color: var(--primary);
            transform: scale(1.1);
        }
    }

    @keyframes dimWindow {
        from { box-shadow: 0 0 0 1000px rgba(0,0,0,0) }
        to { box-shadow: 0 0 0 1000px rgba(0,0,0,.5) }
    }

    .video-playback {
        width: 100%;
        height: 100%;
        /* box-shadow: 0 0 0 1000px rgba(0,0,0,.5); */

        animation-name: dimWindow;
        animation-duration: 1s;
        animation-fill-mode: forwards; 
    }

    .btn {
        background-color: var(--secondary);
        color: #fff;
        margin-left: 30rem;
        border-radius: .5rem;
        margin-top: 2%;
        margin-bottom: 2%;

        &:hover
        {
            background-color: #231f20;
        }
    }

    @media screen and (max-width: 480px) {
        position: relative;
        width: 100%;
        max-width: 100%;
        min-height: 15rem;
        margin: auto;
        margin-bottom: 2rem;

        .video-overlay {

        }

        .video-preview {
            max-width: 100%;
            border-radius: 1rem;
            border-top-left-radius: 0;
            padding: 10px;
            margin-bottom: 1rem;
            height: 15rem;
            min-height: 10rem;
            max-width: 100%;
            min-width: 30%;
        }
    
        .video-title {
            max-width: 1000px;
            font-weight: 800;
            text-align: center;
        }

        .btn-2 {
            margin-left: 8rem;
        }
    }
`

export default VideoDisplay;