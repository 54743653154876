import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MainShell } from '../../main/components/Shell/SharedShell';
import { FindMatch, robots, questions, Robot, Question, Choice } from '../utils/decision';
import Prompt from '../components/Prompt';

const TreeComp:FC<IGlobalProps> = ({ className }) => {
    const [robotList, setRobotList] = useState<Robot<R>[]>();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(1);
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const [renderResult, setRenderResult] = useState(false);
    const [display, setDisplay] = useState<Robot<number[]> | null>();

    useEffect(() => {
        setRobotList(robots);
    },[]);

    useEffect(() => {
        setCurrentQuestion(questions[0])
    },[])

    const handlePromptAnswer = (choice: Choice<number[]>) => {
        
        if (currentQuestionIndex >= questions.length - 1) {
            setDisplay(result());
            setRenderResult(true);
            console.log(display);
            return;
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            console.log(currentQuestionIndex);
            setCurrentQuestion(questions[currentQuestionIndex + 1]);
        }
       setRobotList(FindMatch(choice));
    }

    const result = (): Robot<number[]> | null => {
        const sortedList = robotList?.sort((x, y) => x.score - y.score)
        console.log(sortedList);
        if (!sortedList) {
            return null;
        } else {
            return sortedList[sortedList.length - 1]
        }

        
    }

    return (
        <MainShell className={className}>
            <div className="body">
                <div className="question">
                {renderResult
                ? 
                <div className="result">

                    <h3>You Selected: {display?.name}</h3>
                    <h4>With a matching score of: <span style={{ color: "green" }}>{display?.score}</span></h4>

                    {display?.tags.map((tag, i) => {
                        return <p key={i}>{Object.values(tag)}</p>
                    })}

                    <div className="textarea">
                        congrats! you selected {display?.name}!!! it means you are totally cool
                        and epic. This robot is perfect for you! WOW! You're so strong!
                    </div>
                </div>
                :
                <Prompt>
                    <h4>{currentQuestion?.prompt}</h4>
                    {currentQuestion?.choices.map((q, i) => {
                        return <button key={i} onClick={() => {
                            handlePromptAnswer(q)
                        }
                        }>
                                {q.choice}
                                </button>
                    })}
                </Prompt>
                }
                </div>
                <div className="analytics">
                    {robotList?.map((el, i) => {
                        return (
                            <div className="results-shell" key={i}>
                                <div className="results-contain">{el.name} <h4 className="score"> {el.score} </h4></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </MainShell>
    )
};


const Tree = styled(TreeComp)`

.body {
    background: rgb(2,0,36);
    background: linear-gradient(170deg, rgba(2,0,36,1) 0%, rgba(14,14,66,1) 35%, rgba(80,43,55,1) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    min-height: 100%;

    & .results-shell {
        margin: .5em;
    }

    & .results-contain {
        color: #111111;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
    }

    & h4 {
        background-color: #4189c8;
        text-align: center;
        border-radius: 10px;
        padding: 10px;
        color: antiquewhite;
    }
}

button {
    margin-top: 2em;
    height: 5em;
    width: 15em;
    border: none;
    background-color: salmon;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 2em;
    transition: all 0.3s ease;
}

button:hover {
    transform: scale(1.01);
}

.question {
    background-color: antiquewhite;
    border-radius: 10px;
    padding: 10px;
    max-width: 40em;
    min-width: 40em;
    margin-left: 5em;
}

.analytics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & div {
        background-color: bisque;
        border-radius: 10px;
        border: #111111;
        min-width: 10em;
        max-width: 10em;
        min-height: 10em;
        max-height: 10em;
    }
    & h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        color: antiquewhite;
    }
}

.result {
    max-width: 30em;
    min-width: 30em;
    min-height: 30em;
    max-height: 30em;
}
    
`

export default Tree;