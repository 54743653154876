import { ReactNode } from "react";
import { P as part } from "../../lib";
import { gridElement } from "../../QuickBuilder/components/Nodes/element";



// #region MASS INCLUDES FILTER FUNCTION


export const filterArr = (arr: part[], filter: string[]) => {

//     console.log("array includes OPL: " + filter.includes("opl"));
//     filter.includes("opl") ? arr.filter(el => el.opl.length = 0) : arr.filter(el => el.opl.length > 0);

//     console.log("array includes SOP: " + filter.includes("sop"));
//     filter.includes("sop") ? arr.filter(el => el.sop.length = 0) : arr.filter(el => el.sop.length > 0);

//     console.log("array includes SMP: " + filter.includes("smp"));
//     filter.includes("smp") ? arr.filter(el => el.smp.length = 0) : arr.filter(el => el.smp.length > 0);

//     console.log("array includes EWO: " + filter.includes("ewo"));
//     filter.includes("ewo") ? arr.filter(el => el.ewo.length = 0) : arr.filter(el => el.ewo.length > 0);

//     console.log("array includes OEM: " + filter.includes("oem"));
//     filter.includes("oem") ? arr.filter(el => el.oem == null) : arr.filter(el => el.oem !== null);

//     console.log("array includes Video: " + filter.includes("video"));
//     filter.includes("video") ? arr.filter(el => el.video.length = 0) : arr.filter(el => el.video.length > 0);

    return arr;
}

// #endregion

// #region FILTERS ARRAY STATE FUNCTION / MATCHES

// updates the active filters in the filter array
export const filterState = (arr: string[], filter: string) => {
    const f = arr.filter(el => el !== filter)
    return f;
}

export const PopArr = (arr: any) => {
    arr.pop();
    return arr;
}

export const deleteWhereIndex = (arr: ReactNode[], gridArr: gridElement[], key: number) => {
    // let match: number = gridArr.findIndex((x: any, i: number) => i === key);
    let newArr: any[] = gridArr.filter((x: gridElement) => {
        return x.index !== key;
    })
    return newArr;
}

export const deleteWhereMatch = (arr: ReactNode[], gridArr: gridElement[], key: number) => {
    let newArr: ReactNode[];
    newArr = arr.filter((x,i) => {
        console.log(i, key);
        return i !== key;
    })
    return newArr;
}

export const replaceWhereMatch = (arr: ReactNode[], gridArr: gridElement[], key: number, replace: number) => {
    arr.forEach((n, i) => {
        let node: ReactNode = n;
        let found: number = gridArr.findIndex(x => x.index === replace)
        gridArr[found].el = node;
        return gridArr;
    })
}

export const findAndUpdate = (arr: ReactNode[], el: ReactNode) => {
    // const match = (el: ReactNode, i: number) => el == typeof Empty;
    let match: number = arr.findIndex((e: any) => e.key == null)
    arr[match] = el;
    console.log(arr.indexOf(arr[match]))
    console.log(el)
    return arr;
}

// #endregion


// #region MASTER START / EXPORT FUNCTIONS FOR ARRAY FILTERS

// master start update function / filter. returns filtered array of parts with matching empty sub arrays
export const filterEmpties = (input: part[], filter: string[]): part[] => {
    const match: boolean = true
    const arr = input.filter((e) => {
        return filterEmptyTags(filter, e) === match;
    })
    return arr;
}

// master start update function / filter. returns filtered array of parts with matching tags
export const filterElements = (input: part[], filter: string[]): part[] => {
    const match: boolean = true
    const arr = input.filter((e) => {
        return filterElementTags(filter, e) === match;
    })
    return arr;
}


// #endregion


// #region TAG LOOPS AND MATCHES


// for loop that sends the filter tags to be checked against their matching object sub elements checking for empty arrays
export function filterEmptyTags (filter: string[], e: part): boolean {
        for (let i: number = 0; i <= filter.length; i++) {
            let tag: string = filter[i];
            return isMatchAndEmpty(tag, e, filter)
        }
        return false;
}
// for loop that sends the filter tags to be checked against their matching object sub elements checking for subarrays with value
export function filterElementTags (filter: string[], e: part): boolean {
    for (let i: number = 0; i <= filter.length; i++) {
        let tag: string = filter[i];
        return isMatchAndHasElements(tag, e, filter)
    }
    return false;
}

// #endregion


// #region MATCH


// if element matches filter tag & is an empty array return true;
export function isMatchAndEmpty (tag: string, e: part, filter: string[]): boolean {
    if ( tag in e ) {
        if (subArrayisEmpty(e[tag as keyof part], filter)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false
    }
}

// if element matches filter tag & has elements in its array return true;
export function isMatchAndHasElements (tag: string, e: part, filter: string[]): boolean {
    if ( tag in e ) {
        if (subArrayHasElements(e[tag as keyof part], filter)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false
    }
}

// #endregion


// #region CHECK ELEMENTS


// checks if element has a length attribute
// returns true if length is greater than zero
export function subArrayHasElements(value: any, filter: string[]): boolean {
    if (typeof value === 'object' && 'length' in value) {
        if (greaterThanZero(value)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
// checks if element has a length attribute
// returns true if length is zero
export function subArrayisEmpty(value: any, filter: string[]): boolean {
    if (typeof value === 'object' && 'length' in value) {
        if (notGreaterThanZero(value)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}


// #endregion


// #region GREATER / EQUALS ZERO


// returns true if value is greater than zero
// used in array length checks
export function greaterThanZero(value: any): boolean {
    if (value.length > 0)
    {
        return true;
    }
    else { return false; }
}
// returns false if value is greater than zero
// used in array length checks
export function notGreaterThanZero(value: any): boolean {
    if (value.length === 0)
    {
        return true;
    }
    else { return false; }
}

// #endregion