import React, { ReactNode, createContext, useContext } from 'react';
import { useQBReducer } from './reducers';
import { gridElement } from '../../../QuickBuilder/components/Nodes/element';

/*

QUICK BUILDER CONTEXT INITIALIZER
VALUES THAT EXIST WITHIN THE CONTEXT OF QBAPP.TSX SHOULD BE WRITTEN HERE

*/

export interface State {
    activeEditors: ReactNode[],
    activeImages: ReactNode[],
    activeGrid: gridElement[],
}

const QBContext = createContext<[State, React.Dispatch<any>]>([
    {
        activeEditors: [],
        activeImages: [],
        activeGrid: [],
    },
    () => {}
]);
const { Provider } = QBContext;

const QBProvider = ({ value = [], ...props }) => {
    const [state, dispatch] = useQBReducer({
        activeEditors: [],
        activeImages: [],
        activeGrid: [],
    });
    return <Provider value={[state, dispatch]} {...props} />
};

const useQBContext = () => {
    return useContext(QBContext);
};

export { QBProvider, useQBContext };