import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SET_PART, useQPContext } from '../../../../utils';

import { P as part } from '../../../../lib';

/*

BUTTON THAT HANDLES THE SELECTION OF PARTS INSIDE OF QUICK PARTS
THIS IS BAD PRACTICE, ALL OF THE BUTTONS INSIDE OF THIS CODE BASE NEED TO BE CLEANED AND MADE MODULAR USING CONDITIONAL TYPES AND INTERFACES

- ZB

*/

interface props {
    className: string,
    icon: ReactNode,
    element: part,
    displayName: string, 
    key: string
}

const PartButtonComp: FC<props> = ({ className, icon, element, displayName, key }) => {

    const [state, dispatch] = useQPContext();
    const { activePart } = state;
    const [data, setData] = useState<part>();

    useEffect(() => {
        if (data)
        {
            dispatch({
                type: SET_PART,
                activePart: data
            })
            console.log(activePart);
        }
    },[data, activePart, dispatch]);

    const handleClick = (element: part) => {

        const fetchData = async () => {
            const res = await fetch(`api/part/${element.id}`);
            const d = await res.json();
            setData(d)
        }

        if (element.id)
        {
            fetchData();
        }
        
    }

    return (
        <>
        {displayName !== "" &&
            <section className={className} >
                <div className="button-container">
                    <button onClick={(e) => handleClick(element)}>
                        <h3>{displayName}</h3>
                        {icon}
                        <section className="circle-bar">
                        {/* <div>{element.opl.length > 0 ? <FaCircle size={20} color="forestgreen" /> : null}</div>
                        <div>{element.ewo.length > 0 ? <FaCircle size={20} color="#D0342C" /> : null}</div>
                        <div>{element.smp.length > 0 ? <FaCircle size={20} color="teal" /> : null}</div>
                        <div>{element.oem !== null ? <FaCircle size={20} color="orange" /> : null}</div>
                        <div>{element.sop.length > 0 ? <FaCircle size={20} color="blueviolet" /> : null}</div> */}
                        </section>
                    </button> 
                </div>
            </section>
        }
        </>
    );
};

const PartButton = styled(PartButtonComp)`



.button-container {
    display: flex;
    justify-content: center;
    
}

h3 {
    background: #b8b8b8;
    padding-top: 3px;
    width: 100%;
    height: 4vh;
    color: #1b1b3e;
    font-weight: 800;
    font-size: 22px;
    margin-top: .5em;
    overflow-x: hidden;
    overflow-y: hidden;

    & ::-webkit-scrollbar {
        display: none;
    }
}

Button {
        background: #ffffff;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 1rem;

        & svg {
            color: #1b1b3e;
            margin: auto;
            padding: 5px;
            margin-bottom: .5em;
        }
}

Button:hover, Button:active, Button:focus {
  background: #6eb6e6;
  transform: scale(1.03);

  & h3 {
    background-color: white;
  }
}

.circle-bar {
    display: flex;
    justify-content: center;
}
`

export default PartButton;