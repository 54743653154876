import React, { Route, Routes } from 'react-router-dom';
import { FC, ReactNode } from 'react';

import { Home } from '../QBK/pages/Home';

import Department from '../QBK/pages/Departments';
import Teams from '../QBK/pages/Teams';
import Stations from '../QBK/pages/Stations';

/*

QUICK BOOK OF KNOWLEDGE ROUTE CONTROLLER

-ZB

*/

interface props {
	children?: ReactNode
}

const QBKRouter: FC<props> = ({ children }) => {
	return (
		<Routes>
            <Route path="/qbk/home" element={<Home />} />
            <Route path="/qbk/departments" element={<Department />} />
            <Route path="/qbk/teams" element={<Teams />} />
            <Route path="/qbk/stations" element={<Stations />} />
		</Routes>
	);
};

export default QBKRouter;