import { QBPNG, QPPNG, QRLPNG, QUICKBOTICON } from '../assets';
import { FaBookOpen } from 'react-icons/fa';
import { App } from './app';

export const appList: App[] = [
    {
        name: "QUICK Response Library",
        flavor: "all your documents -- at your fingertips.",
        icon: QRLPNG,
        link: "/qrl/home",
        active: true,
    },
    {
        name: "QUICK Parts",
        flavor: "make connections -- easy",
        icon: QPPNG,
        link: "/qp/home",
        active: false,
    },
    {
        name: "QUICK Builder",
        flavor: "procedure creation -- done simple.",
        icon: QBPNG, 
        link: "/qb/home",
        active: false,
    },
    {
        name: "QUICK BOT",
        flavor: "find what best fits your needs",
        icon: QUICKBOTICON,
        link: "/decider",
        active: true,
    },
    {
        name: "QUICK Book of Knowledge",
        flavor: "find what best fits your needs",
        element: <FaBookOpen size={70} color='darkblue' />,
        link: "/bok",
        active: false,
    }
]