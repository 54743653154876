import styled from 'styled-components';

// component that holds main page content (to the right of the navbar)
const Body = styled.div`
	position: relative;
	height: calc(100vh - var(--header-height)); // (page - header - footer)
	width: calc(100vw - var(--navbar-width)); // (page - navbar)
	top: var(--header-height); // shift past header
	left: var(--navbar-width);

	overflow: auto; // content scrolls inside of other static elements

	@media screen and (max-width: 1024px) {
		width: calc(100vw - var(--navbar-width-mobile));
		left: var(--navbar-width-mobile);
	}

	@media screen and (max-width: 768px) {
		margin: 0 auto;
		width: 95%;
		left: 0;
		top: calc(var(--navbar-height-mobile) + var(--header-height));
	}

	// mobile adjustments
	@media screen and (max-width: 480px) {
		margin: 0 auto;
		width: 95%;
		left: 0;
		top: calc(var(--navbar-height-mobile) + var(--header-height));
	}
`;

export default Body;