import React, { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Editable, Slate} from 'slate-react'
import {
  Editor,
  Descendant,
} from 'slate'

import { TextElement } from '../TextElement'
import Leaf from '../Leaf'

import { EditBar } from '../../../resources'

/* 

TEXT EDITOR CONTROLLER COMPONENT

- ZB

*/

interface props {
    className?: string
    editor: Editor
    index: number
}

const EditTextComp: FC<props> = ({ className, editor, index }) => {
    
    const renderElement = useCallback((props: any) => <TextElement {...props} />, [])
    const renderLeaf = useCallback((props: any) => <Leaf {...props}/>, [])

    // const bold: Text = { bold: true, text: 'bold' }
    // const italic: Text = { italic: true, text: 'italic' }
    // const underline: Text = { underline: true, text: 'underline' }

    const [select, setSelect] = useState(false);
    // const focused = useFocused();

    const handleMouseDown = () => {
      select ? setSelect(false) : setSelect(true)
      console.log(index)
    }

    return (
        <div className={className}>
          <section
            draggable={true}
            onMouseDown={handleMouseDown}
            style={{
            boxShadow: `${select ? '0 0 0 3px #B4D5FF' : 'none'}`,
            }}>
            <EditBar editor={editor} index={index} />
              <Slate editor={editor} initialValue={initialValue}>
                  <Editable
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  placeholder='Enter text'
                  spellCheck
                  autoFocus
                  />

              </Slate>
          </section>
        </div>
    );
}

const EditText = styled(EditTextComp)`
  margin-left: 2em;
  margin-right: 2em;
`

const initialValue: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        { text: " OH WOW EPIC"}
      ],
    }
  ]

export { EditText }