
import { P as part } from "../../lib";

let arr: part[] = [];
let newArr: part[] = [];

const fetchNest = async (limit: number) => {
    const res = await fetch("api/part");
    const data = await res.json();
    arr = await data;
    

    const fetchArrs = async (limit: number) => {
        for (let i = 0; i < limit; i++) {
            const el: part = arr[i];
            
            const res = await fetch(`api/part/${el.id}`);
            const data = await res.json();
            
            if (data)
            {
                newArr.push(await data);
            }
        }
    }

    fetchArrs(limit);
    return newArr;
}

export default fetchNest;