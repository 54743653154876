import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ACTIVE_WINDOW, SET_FILE, useQPContext } from '../../../../utils';
import { FaRegFilePdf } from 'react-icons/fa';
import { File } from '../../../../lib';

/*

COMPONENT THAT RENDERS A LIST OF DOCUMENTS FOR A SELECTED PART

- ZB

*/

interface props {
    className?: string,
}

const DocsListComp: FC<props> = ({ className }) => {

    const [state, dispatch] = useQPContext();
    const { activePart, activeDocs } = state;

    const [el, setEl] = useState<string>('');
    const [window, setWindow] = useState<number>(1);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(`api/part/${activePart.id}`);
            const data = await res.json();
            console.log(data);
        }
        fetchData();
    },[activePart.id, activePart])

    useEffect(() => {
        dispatch({
            type: ACTIVE_WINDOW,
            activeWindow: window
        })
    },[window, dispatch])

    useEffect(() => {
        dispatch({
            type: SET_FILE,
            activeFile: el 
        })
    },[el, dispatch])

    const handleClick = (file: string) => {
        setEl(file)
        setWindow(2);
    }

    const docMap = (el: File[]) => {
        return (
            <section className={className}>
                <section className="list">
                            {el && el.map((p, i) => {
                                console.log(el);
                                return ( 
                                <>
                                <div className="container" onClick={(e) => handleClick(p.file)} key={i}>
                                    <div className="parts-btn">
                                        <h3>New Document</h3>
                                        <FaRegFilePdf size={80} />
                                    </div>
                                </div>
                                </>
                                );
                            })}
                </section>
            </section>
        )
    }

    return (
        <>
        {activeDocs === "smp" && docMap(activePart.smp)}
        {activeDocs === "ewo" && docMap(activePart.ewo)}
        {activeDocs === "sop" && docMap(activePart.sop)}
        {activeDocs === "opl" && docMap(activePart.opl)}
        {activeDocs === "video" && docMap(activePart.video)}
        </>
    )




    // if (activeDocs == "sop")
    // {
    //     return (
    //         <>
    //             <section className={className} >
    //                     <div className="list">
    //                         {part && part.sop.map(p => {
    //                             return <Button className="parts-btn" displayName={p} icon={<FaRegFilePdf size={50} />}/>
    //                         })}
    //                     </div>
    //             </section>
    //         </>
    //     );
    // }
    // if (part && doc == "OPL")
    // {
    //     return (
    //         <>
    //             <section className={className} >
    //                     <div className="list">
    //                         {part && part.OPL.map(p => {
    //                             return <Button className="parts-btn" displayName={p} icon={<FaRegFilePdf size={50} />}/>
    //                         })}
    //                     </div>
    //             </section>
    //         </>
    //     );
    // }
    // if (part && doc == "EWO")
    // {
    //     return (
    //         <>
    //             <section className={className} >
    //                     <div className="list">
    //                         {part && part.EWO.map(p => {
    //                             return <Button className="parts-btn" displayName={p} icon={<FaRegFilePdf size={50} />}/>
    //                         })}
    //                     </div>
    //             </section>
    //         </>
    //     );
    // }
    // if (part && doc == "Manual")
    // {
    //     return (
    //         <>
    //             <section className={className} >
    //                     <div className="list">
    //                         {part && part.Manual.map(p => {
    //                             return <Button className="parts-btn" displayName={p} icon={<FaRegFilePdf size={50} />}/>
    //                         })}
    //                     </div>
    //             </section>
    //         </>
    //     );
    // }


}

const DocsList = styled(DocsListComp)`

.parts-container {

    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);

    & h1 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
    }
}

.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container {
    height: 100%;
    padding: 1em;
    padding-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
}

h3 {
    background: #b8b8b8;
    padding-top: 6px;
    width: 100%;
    height: 4vh;
    color: #1b1b3e;
    font-weight: 800;
    font-size: 21px;
    margin-top: .5em;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: center;

    & ::-webkit-scrollbar {
        display: none;
    }
}

.parts-btn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: #ffffff;
        width: 10em;
        padding: 15px;
        padding: 0;
        border-radius: 1rem;

        & svg {
            color: #1b1b3e;
            margin: auto;
            padding: 5px;
            margin-bottom: .5em;
        }
}

.parts-btn:hover, .parts-btn:active, .parts-btn:focus {
  background: #6eb6e6;
  transform: scale(1.03);

  & h3 {
    background-color: white;
  }
}

`

export default DocsList;