import { FC, useState } from 'react';
import styled from 'styled-components';

import { App } from '../../../lib/app';
import { FaArrowAltCircleDown } from 'react-icons/fa';


interface AddedProps<T> {
    array: T
}

type props = AddedProps<App[]> & IBaseGlobalProps

const DropperComp:FC <props> = ({ className, array }) => {
    const [active, setActive] = useState('');

    const handleDropDownActivate = () => {
       active === '-active' ? setActive('') : setActive('-active');
    }

    const handleDropDownDeactivate = () => {
        active === '-active' ? setActive('') : setActive('-active');
     }
     
    return (
        <div className={className}>
                <div className='drop-topper' onMouseEnter={handleDropDownActivate} onMouseLeave={handleDropDownDeactivate} onTouchStart={handleDropDownActivate} onTouchCancel={handleDropDownDeactivate}>
                    <div className="drop-title">
                        {/* <p>QUICKSUITE</p>  */}
                        <FaArrowAltCircleDown size={20}/>
                    </div>
            	    <div className={`app-drop-down${active}`}>
						    {array.map((el, i) => {
							    return (
                                    <a className="link-wrapper" href={el.link}>
								    <div key={i} className={`app-element${active}`}>
                                        
                                        {el.icon && <img alt={`${el.name} icon`} src={el.icon}></img>}
                                        {el.element && el.element}
									    <p className="app-title">{el.name.slice(5)}</p>
                                        
								    </div>
                                    </a>
							    )
						    })}
					</div>
                </div>
        </div>
    )
};

export const Dropper = styled(DropperComp)`

    display: block;
    z-index: 1000;
    
    & .link-wrapper {
        color: black;
    }

    .drop-topper {
        background-color: antiquewhite;
        width: 15em;
        height: calc(var(--header-height) / 1.5);
        display: block;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1000;

        & .drop-title {
            display: flex;
            justify-content: center;
            font-style: italic;
            font-weight: 500;
            opacity: 80%;

            & svg {
                margin-top: .5em;
            }
        }
    }

    .app-drop-down {
        display: none;
    }

    .app-drop-down-active {
        display: block;
        flex-direction: column;
        background-color: white;
        width: 15em;
        border-radius: 10px;
        z-index: 1000;
    }

    .app-element-active {
        display: flex;
        background-color: white;
        width: 15em;
        min-height: calc(var(--header-height) / 2);
        padding: 1em;
        border-radius: 10px;
        z-index: 1000;

        & img {
            max-width: 2em;
            max-height: 2em;
        }

        & svg {
            max-width: 2em;
            max-height: 2em;
        }

        & .app-title {
            font-style: italic;
            margin-left: 1em;
            font-weight: 600;
        }
    }

    .app-element-active:hover {
        transform: scale(1.03);
        background-color: antiquewhite;
        font-weight: 700;
    }

    .app-element {
        display: none;
    }

    @media screen and (max-width: 480px) {
        .drop-topper {
            width: 12vw;
        }
        .app-drop-down-active {
            width: 12vw;
        }
        .app-element-active {
            width: 12vw;
            justify-content: center;
        }
        p {
            display: none;
        }
    }
    
`