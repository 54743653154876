import React, { Route, Routes } from 'react-router-dom';
import { FC, ReactNode } from 'react';

import DocumentBuilder from '../QuickBuilder/pages/DocumentBuilder';

/*

QUICK BUILDER ROUTE CONTROLLER

-ZB

*/

interface props {
	children?: ReactNode
}

const QBRouter: FC<props> = ({ children }) => {
	return (
		<Routes>
            <Route path="/qb/home" element={<DocumentBuilder />} />
		</Routes>
	);
};

export default QBRouter;