import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface props {
    className?: string
    children: JSX.Element | ReactNode,
}

const ActivityComp:FC <props> = ({ className, children }) => {
    return (
        <div className={className}>
            <div className="title">
                <h1>Recent Activity</h1>
            </div>
            {children}
        </div>
    )
};

const Activity = styled(ActivityComp)`
    border: 1px solid white;
    width: 50vh;
    max-height: 100vh;
    margin-top: 1em;
    border-radius: 10px;
    background-image: linear-gradient(antiquewhite , beige);

    & .title {
        & h1 {
            background-color: #112111;
            color: antiquewhite;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: 10px;
        }
    }
`

export default Activity;