import { FC } from 'react';
import styled from 'styled-components';
import { deptList } from '../../lib/BookLists';
import { Spinner } from '../../resources';
import Container from '../layouts/Container';

const DepartmentsComp:FC <IBaseGlobalProps> = ({ className }) => {
    return (
                <>
                <section className={className}>
                    <Container>
                        <section className="feed-container">
                            <div className={className}>
                                {deptList.map((el, i) => {
                                    return (
                                    <div key={i}>
                                        {el.name}
                                    </div>
                                )
                                })}
                            </div>
                            <div className="spinner">
                                <Spinner />
                            </div>
                        </section>
    
                    </Container>
                </section>
            </>
    )
};

const Departments = styled(DepartmentsComp)`
    
`

export default Departments;