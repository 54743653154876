import React, { FC, ReactNode, useState, useEffect } from 'react'
import styled from 'styled-components';

/*

BUTTON(S) THAT ACTS AS AN MIDDLER BETWEEN DEFINED BUTTONS AND GENERAL BUTTONS INSIDE THE EDITOR

- ZB

*/

interface props {
    className?: string,
    active: boolean
    icon: ReactNode
    onClick?: (e: Event) => void
}

const BuilderButtonComp: FC<props> = ({ className, active, icon, }) => {

    const [act, setAct] = useState<boolean>(false)
    useEffect(() => { setAct(active) }, [active])
    
    return (
        <div className={className}>
        <button className={act ? 'active' : 'inactive'}>
        {icon}
        </button>
        </div>
    );
}

const BuilderButton = styled(BuilderButtonComp)`

margin-left: 3px;
margin-right: 3px;
    
.active {
    background-color: #fff;
}

.inactive {
    background-color: #aaa;
}

button {
    border: none;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(30,30,30,0.3);
}

button:hover {
    transform: scale(1.1);
}

`

export default BuilderButton;

