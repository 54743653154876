import React, { Route, Routes } from 'react-router-dom';
import { FC, ReactNode } from 'react';

import Dashboard from '../QuickParts/pages/Dashboard';

/*

QUICK PARTS ROUTE CONTROLLER

-ZB

*/

interface props {
	children?: ReactNode
}

const QPRouter: FC<props> = ({ children }) => {
	return (
		<Routes>
            <Route path="/qp/home" element={<Dashboard />} />
		</Routes>
	);
};

export default QPRouter;