import { forwardRef, PropsWithChildren, Ref, FC, useState } from 'react'
import styled from 'styled-components'
import { Editor } from 'slate'

import { 
    DeleteButton, 
    AddImageButton, 
    BlockButton, 
    MarkButton 
} from '../../QuickBuilder/components/Buttons'

import { FaBold,
        FaUnderline,
        FaItalic,
        FaAlignCenter,
        FaAlignLeft,
        FaAlignRight,
        FaListOl,
        FaListUl,
        FaQuoteLeft,
        FaHeading,
        FaAlignJustify
} from 'react-icons/fa'

/*

SUB COMPONENTS AND TOOLS PRIMARILY USED FOR QB'S EDITOR

- ZB

*/


interface BaseProps {
    className?: string
    [key: string]: unknown,
    editor: Editor,
    index: number
}

// #region MENU

const MenuComp = forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        />
    )
)

const Menu = styled(MenuComp)`
display: flex;

`

// #endregion

// #region EMPTY

const EmptyComp = forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        />
    )
)

const Empty = styled(EmptyComp)`
    
    width: 3em;
    height: 3em;
    margin: 30% auto;
    border: 3px grey solid;
    border-radius: 10px;

`

// #endregion

// #region TOOLBAR

const ToolbarComp = forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <Menu 
        {...props}
        ref={ref}
        className={className}
        />
    )
)

const EditorForm = forwardRef(
    (
        { className, editor, ...props}: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLFormElement>
    ) => {
        const [image, setImage] = useState<string>()
        function handleChange(e: any) {
            e.preventDefault();
            setImage(URL.createObjectURL(e.target.files[0]))
        }
        return (
            <form ref={ref} {...props}>
            <input type="file" multiple={false} onChange={handleChange}/>
            <AddImageButton editor={editor} image={image} />
            <img
            alt="Upload"
            width='400'
            height='500'
             src={image}></img>
            </form>
        );
    }
)

const Toolbar = styled(ToolbarComp)`

`

// #endregion

// #region EDITBAR

const EditBarComp: FC<BaseProps> = ({ className, editor, index }) => {
    return (
        <Toolbar
        className={className}
        >
        <MarkButton format="bold" icon={<FaBold />} editor={editor} />
        <MarkButton format="italic" icon={<FaItalic />} editor={editor} />
        <MarkButton format="underline" icon={<FaUnderline />} editor={editor} />
        <BlockButton format="heading-one" icon={<FaHeading />} editor={editor} />
        <BlockButton format="block-quote" icon={<FaQuoteLeft />} editor={editor} />
        <BlockButton format="numbered-list" icon={<FaListOl />} editor={editor} />
        <BlockButton format="bulleted-list" icon={<FaListUl />} editor={editor} />
        <BlockButton format="left" icon={<FaAlignLeft />} editor={editor} />
        <BlockButton format="center" icon={<FaAlignCenter />} editor={editor} />
        <BlockButton format="right" icon={<FaAlignRight />}editor={editor} />
        <BlockButton format="justify" icon={<FaAlignJustify />} editor={editor} />
        <DeleteButton editor={editor} isImage={false} index={index}/>  
        </Toolbar>
    )
}

const EditBar = styled(EditBarComp)`
    
display: flex;
padding-top: 1em;
padding-bottom: 1em;

`

// #endregion


export { Menu, Toolbar, EditBar, EditorForm, Empty }