import { FC } from 'react';
import styled from 'styled-components';

interface ContentNavProps {
	className?: string;
}

const ContentNavComp: FC<ContentNavProps> = ({ className }) => {
	return (
		<nav
			id="content-nav"
			className={className}
		>
		</nav>
	);
};

const ContentNav = styled(ContentNavComp)`
	display: flex;
	justify-content: space-between;
	position: relative;
`;

export default ContentNav;