// packages
import { FC } from 'react';
import styled from 'styled-components';
import { FaQuestion } from 'react-icons/fa';
import { MdOutlineLogout } from 'react-icons/md';
import { Tooltip } from '../../../resources';
import { appList } from '../../../lib/AppList';
import { PROFILE } from '../../../assets';
import Helmet from 'react-helmet';
// nested components

// const Logo = require('../Footer/images/its-logo.png');
import { ARROWS } from '../../../assets';
import { Dropper } from './Dropper';

interface props {
	className?: string,
	login?: string
}

// header content and logic
const HeaderComp: FC<props> = ({ className, login }) => {

	return (
		<>
			<Helmet>
				<title>Quick Suite</title>
				<meta property="og:title" content="Quick Suite" />
			</Helmet>
			<header className={className} >
				<div className="header-left">

					<a className="mb-md-0 me-md-auto d-flex" href="/nav">
						<h3 className="header-text">quiksuite</h3>
					</a>

					<a href="/nav">
					<img className="img logo" alt='Logo' src={ARROWS} />
					</a>

					<Dropper array={appList} />
				</div>

				<div className="header-right">
					{
						// do not render buttons on login page
						!login && (
							<div className="header-buttons">
								{/* <Tooltip text="Settings" direction="down" className="d-flex">
									<button>
										<IoMdSettings size={45} />
									</button>
								</Tooltip> */}
								<Tooltip text="Log-Off" direction="down" className="d-flex">
									<button>
										<MdOutlineLogout size={50} />
									</button>
								</Tooltip>
								<Tooltip text="Help" direction="down" className="d-flex">
									<button>
										<FaQuestion size={40} />
									</button>
								</Tooltip>
								<Tooltip text="Profile" direction="down" className="d-flex">
									<button>
										<a href="/profile">
										<img alt='profile icon' src={PROFILE} />
										</a>
									</button>
								</Tooltip>
							</div>
						)
					}
				</div>
			</header>
		</>
	);
};

// header styling
const Header = styled(HeaderComp)`
	z-index: 100; // layer header above anything else
	position: fixed; // lock header at top
	display: flex; // organized left-right
	justify-content: space-between; // header-left and header-right placed on opposite sides
	width: 100%; // width of entire page
	height: var(--header-height);
	margin-top: .2em;
	padding: 0.8vh 0.4vw;
	background-image: linear-gradient(to bottom right, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
	background-repeat: no-repeat;
	background-size: cover;
	backdrop-filter: blur(1em);
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
	border-bottom: solid antiquewhite 5px;




	& .img {
		max-width: 4em;

		&:hover {
			cursor: pointer;
			transform: scale(1.03);
			filter: brightness(1.1);
			transform: rotate(90deg);
		}
	}


	& .header-text {
		color: antiquewhite;
		font-weight: 700;
	}

	& .header-left a {
		/* color: antiquewhite; */
		text-decoration: none;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	// logo spacing
	.header-left {
		/* height: 100%; */
		margin-left: 10px;
		display: flex;
		align-items: center;
		
		h3 {
			font-family: monospace;
			font-weight: 800;
			font-style: oblique;
			text-transform: uppercase;
		}

		img {
			width: 15vw;
			padding-right: .5em;
		}
	}

	// title spacing
	.header-right {
		display: flex;


		& h2 {
			font-weight: bold;
			margin: auto 1rem;
			color: #fff;
		}
		
		.header-buttons {
			display: flex;
			align-items: center;
			margin-right: .5em;

			& button {
				width: 4vw;
				border: none;
				margin: auto;
				align-items: center;
				background-color: transparent;
				border-radius: 10px;

				& svg {
					color: antiquewhite;
					margin: auto;
				}

				& img {
					width: 12vw;
					height: 6vh;
					border-radius: 100%;
					border: 2px solid antiquewhite;
				}

				&:hover {
					transform: scale(1.03);
					filter: brightness(0.9);
				}
			}
		}

		.mobile-menu {
			display: none;
		}
	}

	@media screen and (max-width: 1024px) {

		position: absolute;

		.header-left {
			& h3 {
				display: none;
			}
		}

		.header-right {
			margin-right: 1em;
		}

		.header-buttons {
			justify-content: space-around;
			flex-direction: row;

			& .d-flex {
				display: none;

				& button {
					margin-left: 2vw;
					margin-right: 2vw;
				}
			}
		}

	}

	@media screen and (max-width: 768px) {

		border-bottom: none;
		position: absolute;

		.header-left {
			& h3 {
				display: none;
			}
		}

		.header-right {
			margin-right: 1em;
		}

		.header-buttons {
			justify-content: space-around;
			flex-direction: row;

			& .d-flex {
				display: none;

				& button {
					margin-left: 2vw;
					margin-right: 2vw;
				}
			}
		}
	}
	

	@media screen and (max-width: 480px) {

		border-bottom: none;
		position: fixed;

		.header-left {
			& h3 {
				display: none;
			}
		}

		.header-right {
			margin-right: 1em;
		}

		.header-buttons {
			justify-content: space-around;
			flex-direction: row;

			& .d-flex {
				display: none;

				& button {
					margin-left: 4vw;
					margin-right: 4vw;
				}
			}


		}
	}
`;

export default Header;