import { Lang, Dict } from "./Dict"

export const EN: Dict = new Lang(
    "EN",
    "welcome",
    "documentation",
    "new EWO alerts",
    "upload",
    "part",
    "number",
    "part number",
    "plant",
    "manual",
    "machine",
    "extension",
    "videos",
    "selected",
    "search by part #",
)