import React, { FC, useEffect, useState } from 'react'
import { EditImage, insertImage } from '../ImageElement';
import styled from 'styled-components';
import { FaImage } from 'react-icons/fa';
import { Editor } from 'slate';
import { useQBContext, ADD_IMAGE } from '../../../utils';

/*

BUTTON THAT HANDLES ADD NEW IMAGES TO THE EDITOR

- ZB

*/

interface props {
    className?: string
    editor: Editor
    image?: string
}

const AddImageButtonComp: FC<props> = ({ className, editor, image, }) => {
    const [state, dispatch] = useQBContext();
    const { activeImages, activeEditors } = state;
    const [url, setUrl] = useState<string | undefined>('');


    const onClick = (e: any) => {
        e.preventDefault();
        setUrl(image)
        console.log(url);
        insertImage(editor, url!)
    }

    useEffect(() => {
        console.log('click')
        dispatch({
            type: ADD_IMAGE,
            activeImages: <EditImage editor={editor} index={activeImages.length + activeEditors.length} />
        })
        console.log(activeImages)
    }, [activeEditors.length, activeImages, dispatch, editor])

    return (
        <div className={className}>
            <button 
            onClick={e => onClick(e)}
            >
                
                ++
            <FaImage />
            </button>
        </div>
    )
}

const AddImageButton = styled(AddImageButtonComp)`


    button {
        border: none;
        border-radius: 5px;
        box-shadow: 3px 3px 3px rgba(30,30,30,0.3);
        height: 3em;
        width: 6em;
        margin-left: 1em;
        background-color: salmon
    }

    button:hover {
        transform: scale(1.1);
    }

    svg {
        margin-left: 5px;
    }

`

export default AddImageButton;