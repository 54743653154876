import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import { createEditor } from 'slate';
import { 
    withReact,
    } from 'slate-react';
import { withHistory } from 'slate-history';
import { AddImageButton, AddTextButton } from '../Buttons';
import { useQBContext } from '../../../utils';
import Nodes from '../Nodes';

/*

MAIN EDITOR COMPONENT FOR QUICK BUILDER

- ZB

*/

interface props {
    className?: string
};

// const initialValue: Descendant[] = [
//   {
//     type: 'paragraph',
//     children: [
//       {
//         text:
//           'example paragraph',
//       },
//     ],
//   },
// ]

const MainEditorComp: FC<props> = ({ className }) => {
  const editor = useMemo(
    () => withHistory(withReact(createEditor())),
    []
  )

  const [state] = useQBContext();
  const { activeImages } = state;
  


    return (
        <>
        <div className={className}>
            <div className="editor">
              <div className="buttons">
              <AddTextButton editor={editor} />
              <AddImageButton editor={editor} />
              </div>
              <Nodes />
              {/* {activeEditors.map((el, i) => {
                return <div key={i}>{el}</div>
              })
              } */}
              {activeImages.map((el, i) => {
                return <div key={i}>{el}</div>;
              })
              }
            </div>
        </div>
        </>
    );
};

const MainEditor = styled(MainEditorComp)`
    
.editor {
   margin: 2em auto;
}

.buttons {
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px;
}

button:hover {
    transform: scale(1.1);
  }

  svg {
    margin-left: 5px;
  }

.modal-button {
  border: none;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(30,30,30,0.3);
  height: 3em;
  width: 6em;
  margin-left: 1em;
  background-color: #478b47
}



`

export default MainEditor;