import React, { FC } from 'react';
import styled from 'styled-components';
import { useQPContext } from '../../../../utils'
import { smp } from '../../../../assets';

import { EXVIDEO } from '../../../../assets';

/*

DEMO CODE FOR RENDERING SELECTED DOCUMENT
!!! UNFINISHED !!!

- ZB

*/

interface props {
    className?: string,
    selected: boolean,
    docName?: string
}

const DocPreviewComp: FC<props> = ({ className, selected }) => {

    const [state] = useQPContext();

    const { activeDocs } = state;

    
    return (
        <>
            <section className={className} >
                <h1> {activeDocs} </h1>
                    <div className="preview">
                        {activeDocs === "sop" && <iframe title="smp" src={smp} />}
                        {activeDocs === "video" &&
                        <video controls>
                         <source src={EXVIDEO} type="video/mp4"/>
                        </video>}
                        {activeDocs === "smp" &&
                        <iframe title="smp" src={smp} />
                        }
                        {activeDocs === "ewo" &&
                        <iframe title="smp" src={smp} />
                        }
                        {activeDocs === "opl" &&
                        <iframe title="smp" src={smp} />
                        }
                        {activeDocs === "manual" &&
                        <iframe title="smp" src={smp} />
                        }
                    </div>
            </section>
        </>
    );
}

const DocPreview = styled(DocPreviewComp)`

.parts-container {
    width: 40em;
    height: 45em;
    margin-top: 1em;
    margin-left: 5em;
    margin-bottom: 4em;

    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);

    & h1 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
    }
}

.preview {
    width: 100%;
    height: 45em;
}

iframe {
    width: 100%;
    height: 90%;
}

video {
    width: 100%;
}

`

export default DocPreview;