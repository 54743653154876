import { Department, Team, Station } from "./book";

export const deptList: Department[] = [
    {
        id: '0',
        name: 'Department 0000',
        teams: [
            {
                id: '0',
                name: 'Team 0',
                stations: [
        
                ],
                members: [
        
                ]
            }

        ]
    },
    {
        id: '1',
        name: 'Department 1111',
        teams: [

        ]
    },
    {
        id: '2',
        name: 'Department 2222',
        teams: [

        ]
    },
    {
        id: '3',
        name: 'Department 3333',
        teams: [

        ]
    },
];

export const teamList: Team[] = [
    {
        id: '0',
        name: 'Team 0',
        stations: [

        ],
        members: [

        ]
    }
];

export const stationList: Station[] = [
    {
        id: '0',
        contributors: [

        ],
        name: 'Station 1',
        posts: [],
    },
    {
        id: '1',
        contributors: [

        ],
        name: 'Station 2',
        posts: [],
    },
    {
        id: '2',
        contributors: [

        ],
        name: 'Station 3',
        posts: [],
    },
    {
        id: '3',
        contributors: [

        ],
        name: 'Station 4',
        posts: [],
    },
]