import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ACTIVE_DOCS, ACTIVE_WINDOW, useQPContext } from '../../../../utils';

interface props {
    className?: string,
    docName?: string,
    icon?: ReactNode,
    displayName?: string,
    id?: string,
}

const ButtonComp: FC<props> = ({ className, docName, icon, displayName, id }) => {

    const [state, dispatch] = useQPContext();
    const { activeDocs, activeWindow } = state;

    const [docs, setDocs] = useState<string | undefined>('');
    const [window, setWindow] = useState<number>();

    useEffect(() => {
        dispatch({
            type: ACTIVE_DOCS,
            activeDocs: docName
        })
        console.log(activeDocs);
    },[docs, activeDocs, dispatch, docName])

    useEffect(() => {
        dispatch({
            type: ACTIVE_WINDOW,
            activeWindow: window
        })
        console.log(activeWindow);
    },[window, activeWindow, dispatch])

    const updateSelectedDocument = () => {
        setDocs(docName)

        setWindow(1);
    }

    return (
        <>
            <section className={className} >
                <div className="button-container" onClick={updateSelectedDocument}>
                    <button>
                        <h3>{displayName}</h3>
                        {icon}
                    </button> 
                </div>
            </section>
        </>
    );
};

const Button = styled(ButtonComp)`



.button-container {
    display: flex;
    justify-content: center;
    
}

h3 {
    background: #b8b8b8;
    padding-top: 3px;
    width: 100%;
    height: 4vh;
    color: #1b1b3e;
    font-weight: 800;
    font-size: 22px;
    margin-top: .5em;
    overflow-x: hidden;
    overflow-y: hidden;

    & ::-webkit-scrollbar {
        display: none;
    }
}

Button {
        background: #ffffff;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 1rem;

        & svg {
            color: #1b1b3e;
            margin: auto;
            padding: 5px;
            margin-bottom: .5em;
        }
}

Button:hover, Button:active, Button:focus {
  background: #6eb6e6;
  transform: scale(1.03);

  & h3 {
    background-color: white;
  }
}

.circle-bar {
    display: flex;
    justify-content: center;
}
`

export default Button;