export const SET_FILTER = "SET_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const SET_PART = "SET_PART";
export const SET_MACHINE = "SET_MACHINE";
export const SET_OEM = "SET_OEM";
export const SET_PLANT = "SET_PLANT";
export const UPDATE_PLANTS = "UPDATE_PLANTS";
export const UPDATE_PARTS = "UPDATE_PARTS";
export const FILTER_PARTS = "FILTER_PARTS";
export const UPDATE_OEMS = "UPDATE_OEMS";
export const UPDATE_MACHINES = "UPDATE_MACHINES";
export const SET_MODAL = "SET_MODAL";
export const ACTIVE_WINDOW = "ACTIVE_WINDOW";
export const ACTIVE_DOCS = "ACTIVE_DOCS";
export const SET_DEPARTMENT = "SET_SEPT";
export const SET_EXTENSION = "SET_EXTENSION";
export const SET_BOM = "SET_BOM";
export const SET_FILE = "SET_FILE";