import React, { FC } from 'react'
import styled from 'styled-components';
import { Editor } from 'slate'

import { REMOVE_EDITOR, REMOVE_FROM_GRID, REMOVE_IMAGE, useQBContext } from '../../../utils';

/* 

BUTTON THAT HANDLES DELETING ADDED EDITORS INSIDE THE MAIN BUILD FORM

- ZB

*/

interface props {
    className?: string
    editor: Editor
    isImage: boolean
    index: number
}

const DeleteButtonComp: FC<props> = ({ className, editor, isImage, index }) => {
    const [state, dispatch] = useQBContext();
    const { activeEditors, activeImages, activeGrid } = state;

    const onClick = (e: any) => {
        e.preventDefault();
        console.log(index);
        if (!isImage)
        {
            dispatch({
                type: REMOVE_FROM_GRID,
                activeGrid: activeGrid,
                key: index
            })
            dispatch({
                type: REMOVE_EDITOR,
                activeEditors: activeEditors,
                key: index
            })
            console.log(activeGrid);
        } else if (isImage)
        {
            dispatch({
                type: REMOVE_IMAGE,
                activeImages: activeImages
            })
            dispatch({
                type: REMOVE_FROM_GRID,
                activeGrid: activeGrid
            })
        }

    }
    return (
        <div className={className}>
            <button 
            onClick={e => onClick(e)}
            >
                DELETE
            </button>
        </div>
    )
}

const DeleteButton = styled(DeleteButtonComp)`

float: right;

    button {
        border: none;
        border-radius: 5px;
        box-shadow: 3px 3px 3px rgba(30,30,30,0.3);
        background-color: #e74141;
    }

    button:hover {
        transform: scale(1.1);
    }

    svg {
        margin-left: 5px;
    }

`

export default DeleteButton;