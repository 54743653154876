import { Lang, Dict } from '.'

export const GR: Dict = new Lang(
    "GR",
    "hallo",
    "dokumentation",
    "neue warnungen (EWO)",
    "uploaden",
    "werkzeug",
    "zahl",
    "werkzeugnummer",
    "werk",
    "handbuch",
    "maschine",
    "erwuiterung",
    "videos",
    "ausgewählt",
    "suche nach einem werkzeug",
)