import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

interface props {
	className?: string,
	text: string,
	children: ReactNode,
	direction: string
}

/*

TOOLTIP HOVER CODE WRITTEN BY TT, ADOPTED INTO QUICK SUITE BY ZB,
LARGELY UNIMPLEMENTED BUT COULD BE USEFUL FOR TEACHING USER HOW APPS WORK
OR AS AN ALTERNATIVE TO LEGENDS

- ZB

*/

const TooltipComp: FC<props> = ({ className, text, children, direction }) => {
	const [show, setShow] = useState(0);
	const showState = { opacity: show };

	return (
		<div className={className} onMouseEnter={() => setShow(1)} onMouseLeave={() => setShow(0)}>
			{children}
			<div className={`tool-tip ${direction || 'top'}`} style={showState}>
				{text}
			</div>
		</div>
	);
};

const Tooltip = styled(TooltipComp)`
	width: fit-content;
	position: relative;

	.tool-tip {
		position: absolute;
		border-radius: 4px;
		left: 50%;
		transform: translateX(-50%);
		padding: 6px;
		color: #111111;
		background: white;
		font-size: 14px;
		font-family: sans-serif;
		font-weight: 600;
		line-height: 1;
		z-index: 100;
		white-space: nowrap;
		transition: opacity 0.3s ease-in-out;
	}

	/* CSS border triangles */
	.tool-tip::before {
		content: ' ';
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: 2em;
		margin-left: 0;
	}

	/* Absolute positioning */
	.tool-tip.top {
		top: 0;
	}
	/* CSS border triangles */
	.tool-tip.top::before {
		top: 100%;
		border-top-color: white;
	}

	/* Absolute positioning */
	.tool-tip.right {
		left: 0;
		top: 50%;
		transform: translateX(0) translateY(-50%);
	}
	/* CSS border triangles */
	.tool-tip.right::before {
		left: calc(var(--tooltip-arrow-size) * -1);
		top: 50%;
		transform: translateX(0) translateY(-50%);
		border-right-color: white;
	}

	/* Absolute positioning */
	.tool-tip.bottom {
		bottom: 0;
	}
	/* CSS border triangles */
	.tool-tip.bottom::before {
		bottom: 100%;
		border-bottom-color: white;
	}

	/* Absolute positioning */
	.tool-tip.left {
		left: auto;
		right: 0;
		top: 50%;
		transform: translateX(0) translateY(-50%);
	}
	/* CSS border triangles */
	.tooltip.left::before {
		left: auto;
		right: 0;
		top: 50%;
		transform: translateX(0) translateY(-50%);
		border-left-color: white;
	}
`;

export default Tooltip;
