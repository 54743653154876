import { Lang, Dict } from "./Dict"

export const JP: Dict = new Lang(
    "日本",
    "ようこそ",
    "ドキュメンテーション",
    "新しいアラート (EWO)",
    "アップロード",
    "用具",
    "数",
    "工具番号",
    "工場",
    "手動",
    "機械",
    "延長",
    "ビデオ",
    "選ば れた",
    "ツールを検索する",
)