import React, { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { useQPContext, useGlobalContext, FILTER_PARTS } from '../../../../utils';

import { FaSearch, FaExclamationTriangle } from 'react-icons/fa';
import { File } from '../../../../lib';

/*

FUNCTION BAR FOR ALERT DROP DOWN / SEARCH BAR

- ZB

*/


interface props {
    className?: string
}

const staticEwos = [
    {
        id: 'Warren Truck 7/27'
    },
    {
        id: 'Warren Truck 7/28'
    },
    {
        id: 'Mack Assembly 7/29'
    }
]

const HotbarComp: FC<props> = ({ className }) => {

    const [search, setSearch] = useState<string>('')
    const [ewos, setEwos] = useState<File[]>([]);

    const [state, dispatch] = useQPContext();
    const { filteredParts } = state;
    const [globalState] = useGlobalContext();
    const { activeLang } = globalState;



    useEffect(() => {
        const fetchEWO = async () => {
            const res = await fetch(`/api/EWO`);
            const data = await res.json();
            setEwos(data);
            console.log(ewos);
        }
        fetchEWO();
    },[ewos])

    const handleSearch = async (e: any) => {
        setSearch(e.target.value);
        
        const res = await fetch(`/api/part/number/${search}`);
        const data = await res.json();
        console.log(data);

        if (data.length > 0) {
        dispatch({
            type: FILTER_PARTS,
            filteredParts: data
        })
        }

        console.log(filteredParts);
    }

    return (
        <>
            <section className={className} >
                <div className="hotbar-container">
                    <div className="bar-holder">
                        <div className='search-bar'>
                        <input placeholder={activeLang.search} value={search}
                        onSubmit={(e) => handleSearch(e)} 
                        onChange={(e) => handleSearch(e)} />
                        <FaSearch className="faSearch" size={20} />
                        </div>
                        {/* <Tooltip direction="left" text="Notifications for recently uploaded EWOs will appear here"> */}
                        <div className="ewo-alerts">
                                <select className="ewo-btn">
                                    <option className="ewo-btn">{activeLang.newAlerts}</option>
                                    {staticEwos.map((e, index) => {
                                        return <option key={index} className="ewo-btn">{e.id}</option>
                                    })}
                                </select>
                            <FaExclamationTriangle className='faWarn' size={30} color={"white"} />
                        </div>
                        {/* </Tooltip> */}
                    </div>
                </div>
            </section>
        </>
    );
};

const Hotbar = styled(HotbarComp)`

width: 100%;


.hotbar-container {
    position: relative;
    margin-bottom: 3em;
    top: 6.5em;

    & .faSearch {
        color: white;
        margin-left: 1em;
    }
}

.bar-holder {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin: auto;
}

input {
    margin-top: 1em;
    width: 20em;
    border-radius: 10px;
    margin-left: 2em;
}

.ewo-btn {
    outline: none;
    border: none;
    padding: 8px;
    margin-right: 1em;
    margin-left: 4em;
    margin-top: .5em;
    margin-bottom: .5em;
    background-color: #D0342C;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    text-transform: uppercase;
}
.ewo-btn:hover, .ewo-btn:active, .ewo-btn:focus {
  background: #F47210;
  transform: scale(1.03);
}

h2 {
    float: left;
    margin-top: .25em;
    margin-left: .5em;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
    color: #fff;
}

.ewo-alerts {
    margin-left: 20%;
    overflow-x: auto;
}

.upload-btn {
  text-transform: uppercase;
  outline: 0;
  background: #0069d9;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
  background-color: #0069d9;
  border-radius: 10px;

  & svg {
    margin-left: 1em;
  }
}
.upload-btn:hover, .upload-btn:active, .upload-btn:focus {
  background: #6eb6e6;
}

.delete-btn {
  text-transform: uppercase;
  outline: 0;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
  background-color: #D0342C;
  border-radius: 10px;

  & svg {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.delete-btn:hover, .delete-btn:active, .delete-btn:focus {
  background: #6eb6e6;
}

button {
    margin-left: 1em;
    margin-right: 2em;
}


`

export default Hotbar;