import { Lang, Dict } from "./Dict"

export const CH: Dict = new Lang(
    "中文",
    "你好",
    "文档",
    "新警报 (EWO)",
    "上传",
    "工具",
    "数",
    "刀具编号",
    "厂",
    "手册",
    "机器",
    "外延",
    "视频",
    "选择",
    "搜索工具",
)