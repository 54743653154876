import React, { useState, useEffect, FC } from 'react';
import styled from 'styled-components';

import { SET_PART, useQPContext } from '../../../utils';

import { P as part } from '../../../lib';

/*

TABLE GENERATOR FOR DATA SETS
USES QP CONTEXT, NOT IMPLEMENTED IN ANY MEANINGFUL WAY, SORRY
- ZB

*/

interface props {
    className?: string,
    data: any[],
    element: part
}

const FluidTableComp: FC<props> = ({ className, data, element }) => {

    const [state, dispatch] = useQPContext();

    const { activePart } = state;

    const [keyMap, setKeyMap] = useState<string[]>([]);
    const [item, setItem] = useState<part>();
    // creates an array of keys matching the data set to the table headers
    const keyMapGenerator = (d: part) => {
        let map: string[] = [];
        for (let key in d) {
            console.log(key);
            // remove fk ids from list
            // will probably be done in the back end
            if (!key.includes('Id'))
            {
                map.push(key);
            }
            // map.push(key);
        };
        setKeyMap(map);
    };

    useEffect(() => {
        keyMapGenerator(element);
    },[element])

    useEffect(() => {
        if (item)
        {
          dispatch({
            type: SET_PART,
            activePart: item
          });
        }
        console.log(activePart);
    },[item, activePart, dispatch]);

    const selectItem = (e: part) => {
        setItem(e)
    }
    
    return (
        <section className={className}>
        <table className="table table-striped" aria-labelledby="tableLabel">
          <thead>
            <tr>
                {keyMap.map((e: string) => {
                    return <th>{e.toUpperCase()}</th>
                })}
            </tr>
          </thead>
          <tbody>
            {/* this makes the table work haha! */}
                {data.map((el: part, i: number) => {
                    return (
                    <tr key={i}>
                        {Object.values(el).map((i) => {
                            return (
                            <td><a href="/single-part" onClick={(e) => { e.preventDefault(); selectItem(el); }}>{i!.toString()}</a></td>
                            );
                        })}
                    </tr>
                    )
                })}
          </tbody>
        </table>
        </section>
    )
};

const FluidTable = styled(FluidTableComp)`




`

export default FluidTable;