import React, { FC, ReactNode } from 'react';
import {
  Element as SlateElement,
} from 'slate'

/* 

COMPONENT FOR HANDLING DIFFERENT TEXT ELEMENT TYPES INSIDE OF THE EDITOR

- ZB

*/


interface props {
    className?: string
    attributes?: SlateElement
    children: ReactNode
    element: SlateElement
}

const TextElement: FC<props> = ({ className, attributes, children, element }) => {
    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote {...attributes}>
            {children}
          </blockquote>
        )
      case 'bulleted-list':
        return (
          <ul {...attributes}>
            {children}
          </ul>
        )

        // ordered list case returning error currently!
        
      // case 'ordered-list':
      //   return (
      //     <ol {...attributes}>
      //       {children}
      //     </ol>
      //   )

      case 'heading-one':
        return (
          <h1 {...attributes}>
            {children}
          </h1>
        )
      case 'heading-two':
        return (
          <h2 {...attributes}>
            {children}
          </h2>
        )
      case 'list-item':
        return (
          <li {...attributes}>
            {children}
          </li>
        )
      default:
        return (
          <p {...attributes}>
            {children}
          </p>
        )
    }
  }

export { TextElement }